import React from 'react';

import Config from '../../config.json';
import { Container, Stack, Card, Row, Col } from 'react-bootstrap';
import fetchApi from '../../functions/fetchApi';
import Loading from '../Loading';

import { reduceEventNameLength } from '../../functions/reduceEventNameLength';

import { useParams, Link } from 'react-router-dom';
import ClickableCard from '../ClickableCard';
import { useMatomo } from '@datapunt/matomo-tracker-react'

function SessionsPage(props) {
    let { season, progressiveRoundNumber } = useParams();

    const [sessions, setSessions] = React.useState(null);
    const [eventInfo, setEventInfo] = React.useState(null);
    const [eventDates, setEventDates] = React.useState(null);

    const { trackPageView, trackEvent } = useMatomo()
    React.useEffect(() => {
        trackPageView();
    }, [])

    React.useEffect(() => {
        fetchApi(Config.apiEndpoint + '/' + season + '/' + progressiveRoundNumber + '/sessions', (sessions) => setSessions(sessions));

        fetchApi(Config.apiEndpoint + '/' + season + '/' + progressiveRoundNumber + '/eventTimes', (eventDates) => setEventDates(eventDates['data'] ?? []));

        fetchApi(Config.apiEndpoint + '/' + season + '/' + progressiveRoundNumber + '/info',
            (eventInfo) => {
                props.setPath((oldPath) =>
                    [{
                        text: <i className="bi bi-house-door"></i>,
                        url: '/',
                    }, {
                        text: season,
                        url: '/' + season,
                    }, {
                        text: reduceEventNameLength(eventInfo.name),
                        url: '/' + season + '/' + progressiveRoundNumber,
                    }, {
                        text: 'Sessioni',
                        url: '/' + season + '/' + progressiveRoundNumber + '/sessions',
                        disabled: true,
                    }]
                );

                setEventInfo(eventInfo);
            });
    }, []);


    if (sessions === null || eventInfo === null || eventDates === null) {
        return <Loading />
    }



    let minEvent = {
        eventIndex: undefined,
        eventDeltaTime: undefined
    }
    for (const eventIndex in eventDates) {
        const diffTime = (new Date(eventDates[eventIndex] * 1000) - new Date()) / (1000 * 60 * 60 * 24);
        if (diffTime < 0 && Math.abs(diffTime) < 7 || diffTime >= 0 && Math.abs(diffTime) < 3) {
            if (minEvent.eventDeltaTime == undefined || Math.abs(minEvent.eventDeltaTime) > Math.abs(diffTime)) {
                minEvent.eventIndex = eventIndex;
                minEvent.eventDeltaTime = diffTime;
            }
        }
    };


    const estimateClosestEvent = true;



    const DateRow = (props) => (
        <Row>
            <span>
                <span>
                    <i className="bi bi-calendar3"></i>
                </span>
                <span>&nbsp;</span>
                <span className='ml-2'>
                    {
                        new Date(props.date * 1000)
                            .toLocaleDateString(
                                "it-IT",
                                { month: 'short', day: 'numeric' },
                            )
                    }
                    &nbsp;-&nbsp;
                    {
                        new Date(props.date * 1000)
                            .toLocaleTimeString(
                                "it-IT",
                                { hour: '2-digit', minute: '2-digit' },
                            )
                    }
                </span>
            </span>
        </Row>
    );




    return (
        <Stack direction="vertical" gap={4}>
            {
                sessions.map((session, index) =>
                    <Link to={'/' + season + '/' + progressiveRoundNumber + '/' + session.progressiveSessionNumber} key={session.progressiveSessionNumber} className="clickable-card">
                        <ClickableCard
                            title={session.name}
                            subtitles={[
                                eventDates[session.progressiveSessionNumber] != undefined ? <DateRow date={eventDates[session.progressiveSessionNumber]} /> : null
                            ]}
                            color={(estimateClosestEvent && session.progressiveSessionNumber == minEvent.eventIndex) ? 'success' : null}
                            rightColumn={(estimateClosestEvent && session.progressiveSessionNumber == minEvent.eventIndex) ? <i className="bi bi-clock-history text-right text-success"></i> : undefined}
                        />
                    </Link>

                )
            }
        </Stack>
    );
}

export default SessionsPage;
