// import logo from './logo.svg';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import React from 'react';

import SeasonsPage from './components/pages/SeasonsPage';
import EventsPage from './components/pages/EventsPage';
import EventChoicePage from './components/pages/EventChoicePage';
import SessionsPage from './components/pages/SessionsPage';
import SessionChoicePage from './components/pages/SessionChoicePage';
import RaceControlPage from './components/pages/RaceControlPage';
import DriversPage from './components/pages/DriversPage';
import DocumentsPage from './components/pages/DocumentsPage';
import DocumentPage from './components/pages/DocumentPage';
import PageLayout from './components/PageLayout';
import LapsPage from './components/pages/LapsPage';
import ChartsPage from './components/pages/ChartsPage';
import OnePagePdf from './components/OnePagePdf.js';
import StatisticsPage from './components/pages/StatisticsPage.js';
import DataManagerPage from './components/pages/DataManagerPage.js';

import Loading from './components/Loading';


import Config from './config.json';



import LoginPage from './components/pages/LoginPage';


import { BrowserRouter, Routes, Route } from 'react-router-dom';
import fetchApi from './functions/fetchApi';


function App() {


	const [path, setPath] = React.useState([{
		text: <i className="bi bi-house-door"></i>,
		url: '/',
	}]);

	let localStorageSelectedLaps = JSON.parse(window.localStorage.getItem('selectedLaps'));

	const [selectedLaps, setSelectedLaps] = React.useState(
		localStorageSelectedLaps === null ? [] : localStorageSelectedLaps
	);

	const [loggedIn, setLoggedIn] = React.useState(null);

	const [loginCheckCounter, setLoginCheckCounter] = React.useState(0);

	const isAdmin = () => {
		if (window.localStorage.getItem('userProfile') == undefined) return false;
		const userProfile = JSON.parse(window.localStorage.getItem('userProfile'));
		return (userProfile['role'] !== undefined && userProfile['role'] == 'admin');
	}


	function checkLogin() {
		console.log("check login");
		fetchApi(Config.apiEndpoint + '/login/check', (loginCheck) => {
			if (loginCheck.loggedIn === true) {
				setLoggedIn(true);
			} else {
				setLoggedIn(false);
				window.localStorage.removeItem('loginToken');
				window.localStorage.removeItem('userProfile');
			}
		});
	}


	React.useEffect(() => {
		//checkLogin();
	}, []);

	React.useEffect(() => {
		if (loginCheckCounter <= 0) {
			setLoginCheckCounter(5 * 60);
			checkLogin();
		}

		const id = setInterval(() => {
			setLoginCheckCounter(loginCheckCounter - 30);
		}, 30 * 1000);
		return () => clearInterval(id);
	}, [loginCheckCounter]);



	React.useEffect(() => {
		window.localStorage.setItem(
			'selectedLaps',
			JSON.stringify(selectedLaps)
		);
	}, [selectedLaps]);




	let LoggedInRoutes =
		<Routes>

			<Route path="/">
				<Route index element={<SeasonsPage setPath={setPath} />} />

				<Route path="charts">
					<Route index element={<ChartsPage selectedLaps={selectedLaps} setSelectedLaps={setSelectedLaps} setPath={setPath} />} />
					<Route path=":newLocalStorage" element={<ChartsPage selectedLaps={selectedLaps} setSelectedLaps={setSelectedLaps} setPath={setPath} />} />
				</Route>

				<Route path="/statistics" element={<StatisticsPage setPath={setPath} />} />

				{
					isAdmin() && <Route path="/data">
						<Route index element={<DataManagerPage setPath={setPath} />} />
						<Route path=":season" element={<DataManagerPage setPath={setPath} />} />
						<Route path=":season/:progressiveRoundNumber" element={<DataManagerPage setPath={setPath} />} />
						<Route path=":season/:progressiveRoundNumber/:progressiveSessionNumber" element={<DataManagerPage setPath={setPath} />} />
					</Route>
				}





				<Route path=":season">
					<Route index element={<EventsPage setPath={setPath} />} />

					<Route path=":progressiveRoundNumber">
						<Route index element={<EventChoicePage setPath={setPath} />} />

						<Route path="sessions" element={<SessionsPage setPath={setPath} />} />

						<Route path="documents">
							<Route index element={<DocumentsPage setPath={setPath} />} />
							<Route path=":documentPath" element={<DocumentPage setPath={setPath} />} />
							<Route path=":documentPath/:documentIndex" element={<DocumentPage setPath={setPath} />} />
						</Route>


						<Route path=":progressiveSessionNumber">
							<Route index element={<SessionChoicePage setPath={setPath} />} />

							<Route path="laps" element={<DriversPage setPath={setPath} />} />
							<Route path="laps/:racingNumber" element={<LapsPage setPath={setPath} selectedLaps={selectedLaps} setSelectedLaps={setSelectedLaps} />} />

							<Route path="raceControl" element={<RaceControlPage setPath={setPath} />} />
						</Route> {/* session is known (/season/round/session/...) */}
					</Route> {/* round is known (/season/round/...) */}
				</Route> {/* season is known (/season/...) */}
			</Route> {/* all routes (/...) */}

		</Routes>



	let LoginRoutes =
		<Routes>
			<Route path="*" element={<LoginPage setLoggedIn={setLoggedIn} />} />
		</Routes>


	if (loggedIn === null) {
		return (
			null
		);
	}

	if (loggedIn === false) {
		return (
			<BrowserRouter>
				{LoginRoutes}
			</BrowserRouter>
		);
	}




	return (
		<BrowserRouter>
			<PageLayout path={path} setLoggedIn={setLoggedIn}>

				{LoggedInRoutes}


			</PageLayout>
		</BrowserRouter>
	);

}

export default App;


/*
<div className="App">
	  <header className="App-header">
		<img src={logo} className="App-logo" alt="logo" />
		<p>
		  Edit <code>src/App.js</code> and save to reload.
		</p>
		<a
		  className="App-link"
		  href="https://reactjs.org"
		  target="_blank"
		  rel="noopener noreferrer"
		>
		  Learn React
		</a>
	  </header>
	</div>
*/
