import React from 'react';

import Config from '../../config.json';
import { Container, Stack, Card, Row, Col } from 'react-bootstrap';
import fetchApi from '../../functions/fetchApi';
import Loading from '../Loading';
import ClickableCard from '../ClickableCard';


import { Link } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react'

function SeasonsPage(props) {

    const [seasons, setSeasons] = React.useState(null);
    const [closestEvent, setClosestEvent] = React.useState(null);

    const { trackPageView, trackEvent } = useMatomo()
    React.useEffect(() => {
        trackPageView();
    }, [])

    React.useEffect(() => {

        fetchApi(Config.apiEndpoint + '/seasons', (seasons) => {


            // now, check the events of the current year
            fetchApi(Config.apiEndpoint + '/' + seasons[0] + '/events', (events) => {
                let min = null;
                events.map((event, index) => {
                    const diffTime = Math.round((new Date(event.date) - new Date()) / (1000 * 60 * 60 * 24));
                    if (diffTime < 0 && Math.abs(diffTime) < 7 || diffTime >= 0 && Math.abs(diffTime) < 3) min = index;
                });
                if (min !== null) setClosestEvent(events[min]);

                setSeasons(seasons);
            });

        });

        props.setPath((oldPath) => [{
            text: <i className="bi bi-house-door"></i>,
            url: '/',
        }]);

    }, []);

    if (seasons === null) {
        return <Loading />
    }

    const DateRow = (props) => {
        return (<Row>
            <span>
                <span>
                    <i className="bi bi-calendar3"></i>
                </span>

                <span>&nbsp;</span>

                <span className='ml-2'>
                    {
                        new Date(props.date)
                            .toLocaleDateString(
                                "it-IT",
                                { month: 'long', day: 'numeric' },
                            )
                    }
                </span>
            </span>
        </Row>);
    }




    const closestEventElement = (closestEvent !== null) ? (
        <Row>
            <Col className='g-4'>
                <Link to={'/' + seasons[0] + '/' + closestEvent['progressiveRoundNumber']} className="col clickable-card">
                    <ClickableCard
                        title={closestEvent.officialName}

                        subtitles={[
                            closestEvent.location,
                            closestEvent.country,
                            <DateRow date={closestEvent.date} />
                        ]}

                        color={'success'}

                        labels={
                            closestEvent.format == 'testing' ? [['Test', 'secondary']] : closestEvent.format == 'sprint' ? [['Sprint', 'danger']] : null
                        }

                        rightColumn={<i className="bi bi-clock-history text-right text-success"></i>}

                    //text=''
                    />
                </Link>
            </Col>

        </Row>

    ) : '';

    const seasonList = (
        <Row>
            {
                seasons.map((season, index) =>
                    <Col key={index} className='col-12 col-sm-6 g-4'>
                        <Link to={'/' + season} key={season} className="clickable-card">
                            <Card className="shadow choice-card">
                                <Card.Body>
                                    <Card.Title className="my-0">
                                        {season}
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                )
            }
        </Row>
    );

    return (
        <Row>
            <Col className='col col-12 col-sm-6'>
                {closestEventElement}
            </Col>
            <Col className='col col-12 col-sm-6'>
                {seasonList}
            </Col>
        </Row>
    );
}

export default SeasonsPage;