import { Container, Stack, Card, Row, Col, Badge } from 'react-bootstrap';

function ClickableCard({ title, subtitles, rightColumn, text, color, labels }) {
    let index = 1;
    let min = 1;

    let bsColors = ['success', 'danger', 'warning', 'primary', 'secondary'];

    // it is true when we can use the bootstrap color, false otherwise
    let useBsColor = bsColors.includes(color);

    let cardStyle = {};
    let cardBodyStyle = {};


    let iconRight = () => {
        if (rightColumn != undefined) {
            return (
                <span className="position-absolute top-0 start-100 translate-middle p-0 m-0 bg-white text-black" style={{ 'borderRadius': '50%' }}>
                    <h5 className='m-0' style={{ 'lineHeight': '1', 'padding': '2px' }}>{rightColumn}</h5>
                </span>
            );
        }

    }

    if (subtitles == undefined) subtitles = [];
    if (labels == undefined) labels = [];


    if (color && !useBsColor) {
        cardStyle.borderColor = color;
        cardBodyStyle.color = color;
    }

    //if (!Array.isArray()) subtitles = [subtitles];

    return (
        <Card key={title} style={cardStyle} className={"shadow position-relative choice-card h-100" + (useBsColor ? ' border-' + color : '')}>
            <Card.Body
                className={(useBsColor ? ' text-' + color : '')}
                style={cardBodyStyle}
            >
                <Card.Title>
                    {title}
                </Card.Title>

                {
                    subtitles.map((subtitle, index) => {
                        return (
                            <Card.Subtitle key={subtitle + index} className={"mb-2"}>
                                {subtitle}
                            </Card.Subtitle>
                        )
                    })
                }

                <Card.Text>
                    {text}
                </Card.Text>

                {
                    labels.map((label, index) => {
                        let bgClass = bsColors.includes(label[1]) ? label[1] : '';
                        let bgStyle = { "backgroundColor": label[1], "color": label[2] };
                        return (
                            <Badge bg={bgClass} style={bgStyle} key={'label-' + index}>
                                {label[0]}
                            </Badge>
                        )
                    })
                }

                {iconRight()}

            </Card.Body>
        </Card>
    );


}

export default ClickableCard;