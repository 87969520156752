import React from 'react';

import Config from '../../config.json';
import { Stack, Card, Row, Container, Col } from 'react-bootstrap';
import fetchApi from '../../functions/fetchApi';
import Loading from '../Loading';
import ClickableCard from '../ClickableCard';
import { Document, Page } from "react-pdf";

import { useParams, Link } from 'react-router-dom';

import { reduceEventNameLength } from '../../functions/reduceEventNameLength';

import { useMatomo } from '@datapunt/matomo-tracker-react'
import OnePagePdf from '../OnePagePdf.js';

function DocumentPage(props) {
    let { season, progressiveRoundNumber, documentPath, documentIndex } = useParams();

    const [document, setDocument] = React.useState(null);
    const [eventInfo, setEventInfo] = React.useState(null);

    const { trackPageView, trackEvent } = useMatomo()
    React.useEffect(() => {
        trackPageView();
    }, [])


    React.useEffect(() => {
        const apiPath = Config.apiEndpoint + '/' + season + '/' + progressiveRoundNumber + '/pdf/' + documentPath + '/' + (documentIndex !== undefined ? documentIndex : '0');


        fetchApi(apiPath, (document) => {
            setDocument(document);

            fetchApi(Config.apiEndpoint + '/' + season + '/' + progressiveRoundNumber + '/info',

                (eventInfo) => {
                    props.setPath((oldPath) =>
                        [{
                            text: <i className="bi bi-house-door"></i>,
                            url: '/',
                        }, {
                            text: season,
                            url: '/' + season,
                        }, {
                            text: reduceEventNameLength(eventInfo.name),
                            url: '/' + season + '/' + progressiveRoundNumber,
                        }, {
                            text: 'Documenti',
                            url: '/' + season + '/' + progressiveRoundNumber + '/documents',
                        }, {
                            text: document.title,
                            url: '/' + season + '/' + progressiveRoundNumber + '/documents/' + documentPath + '/' + (documentIndex ?? 0),
                            disabled: true,
                        }]
                    );

                    setEventInfo(eventInfo);
                }
            );
        });

    }, []);


    if (document === null || eventInfo === null) {
        return <Loading />
    }


    function b64toBlob(b64Data, contentType) {
        contentType = contentType || '';
        var sliceSize = 512;
        b64Data = b64Data.replace(/^[^,]+,/, '');
        b64Data = b64Data.replace(/\s/g, '');
        var byteCharacters = window.atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }





    const file = b64toBlob(document.pdf, 'application/pdf');
    const blobUrl = URL.createObjectURL(file);






    let subtitles = [];

    subtitles.push((<><i className="bi bi-tag"></i>{' '}{document.title}</>));
    subtitles.push((<><i className="bi bi-calendar-week"></i>{' '}
        {new Date(document.date)
            .toLocaleDateString(
                "it-IT",
                { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' },
            )}
    </>));



    const timeDifference = Math.round((new Date(document.date) - new Date()) / 1000);
    const maxDateConversion = 86400;
    if (Math.abs(timeDifference) <= maxDateConversion) {
        subtitles.push((
            <span>
                <i className="bi bi-hourglass-split"></i>{' '}
                {
                    Math.round(Math.abs(timeDifference) / 3600) + " ore e " +
                    Math.round(Math.abs(timeDifference) % 3600 / 60) + " minuti fa"
                }
            </span>
        ));
    }




    const pageTitle = (

        <Row className="row row-cols-1 mb-4">
            <Col>
                <ClickableCard
                    title={"Consultazione documento"}
                    subtitles={subtitles}
                    rightColumn={<i className="bi bi-info-circle"></i>}
                ></ClickableCard>
            </Col>
        </Row>

    );

    const documentSize = (
        <span>
            <i className="bi bi-hdd-fill"></i> Dimensione: {
                (document.pdf.length < 1024 * 1024) ?
                    Math.round(document.pdf.length * 3 / 4 / 1024 * 10) / 10 + " KB" :
                    Math.round(document.pdf.length * 3 / 4 / 1024 / 1024 * 10) / 10 + " MB"
            }
        </span>
    );

    const downloadButton = (

        <Row className="row row-cols-1 mb-4">
            <a href={blobUrl} className="col clickable-card" target='_blank'>
                <ClickableCard
                    title={(<><i className="bi bi-cloud-arrow-down-fill"></i> Scarica documento</>)}
                    subtitles={[
                        documentSize,
                    ]}
                    color='success'
                //rightColumn={<i className="bi bi-info-circle"></i>}
                //text={"Sono presenti  documenti consultabili."}
                ></ClickableCard>
            </a>
        </Row>

    );


    const documentRender = (

        //<Row className="row row-cols-1 mb-4">
        //    <Col>
        <Container>
            <Card id='pdfContainer'>
                <OnePagePdf pdf={'data:application/pdf;base64,' + document.pdf} />
            </Card>
        </Container>
        //    </Col>
        //</Row>

    );

    return <>
        {pageTitle}
        {downloadButton}
        {documentRender}
    </>;

}

export default DocumentPage;