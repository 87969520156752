import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// import reportWebVitals from './reportWebVitals';

import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

const instance = createInstance({
	urlBase: 'https://matomo.rossoandrea.it',
	srcUrl: 'https://matomostats.rossoandrea.it/mtm.js',
	trackerUrl: 'https://matomostats.rossoandrea.it/mtm.php',
	siteId: 2,
	configurations: {
		setRequestMethod: 'POST'
	}
});



ReactDOM.render(
	<React.StrictMode>
		<MatomoProvider value={instance}>
			<App />
		</MatomoProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


// reportWebVitals();
