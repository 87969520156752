import { Container, Stack, Card, Row, Col, Badge, Alert } from 'react-bootstrap';

function MessageBox({ text, rightColumn, topRightIcon, variant, labels }) {




    let iconRight = () => {
        if (topRightIcon != undefined) {
            return (
                <span className="position-absolute top-0 start-100 translate-middle p-0 m-0 bg-white text-black" style={{ 'borderRadius': '50%' }}>
                    <h5 className='m-0' style={{ 'lineHeight': '1', 'padding': '2px' }}>{topRightIcon}</h5>
                </span>
            );
        }

    }

    const body = (
        <Row className="align-items-center">
            <Col className={"d-flex flex-column justify-content-center col-" + (rightColumn != undefined) ? '10' : '12'}>
                {text}
            </Col>
            {
                (rightColumn != undefined) ? (
                    <Col className="col-2 fs-1 text-end">
                        {rightColumn}
                    </Col>
                ) : ''
            }
        </Row>
    );

    return (
        <Alert key={text} variant={variant}>
            {body}
            {iconRight()}
        </Alert>
    );


}

export default MessageBox;