import React from 'react';
import { Container, Navbar, Nav, NavItem, Badge, InputGroup, SplitButton, Dropdown, FormControl, NavDropdown, Modal, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

import DataManagerPlane from './pages/DataManagerPage';

function Header(props) {

    const [show, setShow] = React.useState(false);


    const logout = () => {
        window.localStorage.removeItem('loginToken');
        props.setLoggedIn(false);
    };

    const isAdmin = () => {
        const userProfile = JSON.parse(window.localStorage.getItem('userProfile'));
        return (userProfile['role'] !== undefined && userProfile['role'] == 'admin')
    }

    const dataUrlRegex = /(^\/\d{4}\/?\d{0,2}\/?\d{0,2})/;
    const currentLocation = useLocation().pathname;

    let dataUrl = '/data' + (dataUrlRegex.test(currentLocation) ? dataUrlRegex.exec(currentLocation)[0] : '');

    const modal = isAdmin() ? (
        <Modal show={show} size="lg" onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Gestione dei dati
                    {' '}
                    <Link to={dataUrl} onClick={() => setShow(false)}>
                        <span className='text-secondary'>
                            <i className={"bi bi-box-arrow-up-right"}></i>
                        </span>
                    </Link>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DataManagerPlane></DataManagerPlane>



            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Chiudi
                </Button>
            </Modal.Footer>
        </Modal>
    ) : '';

    return (<>
        <Navbar bg="light" expand="sm">
            <Container fluid className="px-4">
                <Navbar.Brand href="/">
                    <i className="bi bi-speedometer"></i> Telemetrie Web <Badge bg="secondary">Beta</Badge>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <NavItem>
                            <Link to='/charts' style={{ textDecoration: 'none' }} role='button' className='nav-link'>
                                <i className="bi bi-graph-up"></i> Grafici
                            </Link>
                        </NavItem>
                        {
                            isAdmin() && <>
                                {modal}

                                <NavItem>
                                    <a role='button' className='nav-link' onClick={() => {
                                        setShow(true);
                                    }}>
                                        <i className={"bi bi-cloud-arrow-down-fill"}></i> Dati
                                    </a>

                                </NavItem>
                            </>
                            /*
                            <NavItem>
                                <Link to={dataUrl} style={{ textDecoration: 'none' }} role='button' className='nav-link'>
                                    <i className="bi bi-cloud-arrow-down-fill"></i> Dati
                                </Link>
                            </NavItem>
                            */
                        }

                    </Nav>
                    <Nav className="navbar-right">
                        <NavItem>
                            <span role='button' className='nav-link' onClick={logout}>
                                {JSON.parse(window.localStorage.getItem('userProfile'))['name']}{' '}
                                <i className="bi bi-box-arrow-right"></i>
                            </span>


                        </NavItem>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>


    </>);

}

export default Header;
