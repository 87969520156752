
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


function ValueChart(props) {
    const options = {
        responsive: true,
        elements: {
            point: {
                radius: 0,
            }
        },
        scales: {
            linearScale: {
                type: 'linear',
                position: 'bottom',
            }
        },
        plugins: {
            legend: {
                position: 'top',
                display: false,
            },
            title: {
                display: true,
                text: props.title,
            },
        },

    };


    let datasets = [];

    props.laps.forEach((lap, index) => {

        datasets.push({

            label: ' ',
            data: lap[props.valueType],

            borderColor: lap.lineColor,
            backgroundColor: lap.lineColor,

            type: 'line',

            stepped: props.isStepped ?? false,
        });

    });

    const data = {
        datasets: datasets,
    };

    return <Line options={options} data={data} />
}

export default ValueChart;
