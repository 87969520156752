import React from 'react';


import { Stack, Card, Button, Row, Col, Container } from 'react-bootstrap';

import Config from '../../config.json';
import Loading from '../Loading';

import fetchApi from '../../functions/fetchApi';

import loadTelegramWidget from '../loadTelegramWidget';


function LoginPage(props) {

    const [loginProviders, setLoginProviders] = React.useState(null);
    const [telegramWidgetLoaded, setTelegramWidgetLoaded] = React.useState(false);

    React.useEffect(() => {

        loadTelegramWidget(() => {
            setTelegramWidgetLoaded(true);
        });

    }, []);

    React.useEffect(() => {

        fetchApi(Config.apiEndpoint + '/login/providers', (loginProviders) => {
            setLoginProviders(loginProviders);
        });

    }, []);


    if (loginProviders === null || telegramWidgetLoaded !== true) {
        return <Loading />
    }


    const handleTelegramButtonClick = (e) => {

        window.Telegram.Login.auth(
            { bot_id: Config.telegramBotID, request_access: true },

            (data) => {
                if (!data) {
                    // authorization failed
                    return;
                }

                const params = new URLSearchParams(data);

                fetchApi(Config.apiEndpoint + '/login/provider/telegram?' + params.toString(), (tokenData) => {
                    setLoginInfo(tokenData);
                });
            }
        );

    }


    const setLoginInfo = (loginData) => {

        if (loginData.loggedIn === true) {

            fetchApi(Config.apiEndpoint + '/login/token/' + loginData.requestToken, (tokenData) => {

                if (tokenData.success === true) {

                    window.localStorage.setItem('loginToken', tokenData.token);
                    window.localStorage.setItem('userProfile', JSON.stringify({
                        profile: loginData.userProfile,
                        role: loginData.role,
                        name: loginData.name,
                        surname: loginData.surname,
                        userId: loginData.userId,
                        picture: loginData.picture,
                    }));
                    props.setLoggedIn(true);
                }

            });

        }
    }


    const handleLoginButtonClick = (loginProviderName) => {
        const url = Config.apiEndpoint + '/login/provider/' + loginProviderName.toLowerCase();

        let newWindow = window.open(url);

        window.addEventListener('message', (e) => {

            if (Config.apiEndpoint.startsWith(e.origin) === false) {
                return;
            }

            newWindow.close();

            setLoginInfo(JSON.parse(e.data));
        });
    }


    return (
        <Container className='mt-5'>

            <Row>
                <Col className='col-md-4 offset-md-4'>
                    <Stack gap={3}>

                        {loginProviders.filter((loginProvider) => loginProvider.name.toLowerCase() != 'telegram').map((loginProvider) => {
                            // Add any login provider except Telegram, which is handled separately
                            return (
                                <Button
                                    variant="outline-primary"
                                    onClick={() => handleLoginButtonClick(loginProvider.name)}
                                    key={loginProvider.name}
                                >
                                    <i className={loginProvider.icon}></i> {loginProvider.name}
                                </Button>
                            );
                        }

                        )}

                        <Button
                            variant="outline-primary"
                            onClick={handleTelegramButtonClick}
                            key="Telegram"
                        >
                            <i className="bi bi-telegram"></i> Telegram
                        </Button>

                    </Stack>
                </Col>
            </Row>

        </Container>
    );

}


export default LoginPage;