import React from 'react';

import BasicTable from './BasicTable';

import { Badge, Button, Form, Card, Row } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import Loading from './Loading';

import { reduceEventNameLength } from '../functions/reduceEventNameLength';

import FlagSymbol from './FlagSymbol';

import moment from 'moment';


function LapsTable(props) {

    /* getting max number of microsectors */
    const [microsectors, setMicrosectors] = React.useState(null);


    React.useEffect(() => {
        let newMicrosectors = {
            1: 0,
            2: 0,
            3: 0,
        };

        props.laps.forEach((lap) => {
            lap.trackTimes.forEach((trackTime) => {
                if (trackTime.microSectorNumber > newMicrosectors[trackTime.sectorNumber]) {
                    newMicrosectors[trackTime.sectorNumber] = trackTime.microSectorNumber;
                }
                // if(props.selectedLaps)
            });
            //console.log(microsectors);
        });
        setMicrosectors(newMicrosectors);
    }, [props.laps]);


    const formatTrackPartTime = (trackPartTime, isMicrosectorTime) => {

        const momentTime = moment(trackPartTime, 'HH:mm:ss.SSS');

        if (isMicrosectorTime === true && momentTime.diff(moment().startOf('day'), 'seconds') >= 50) {
            // very slow microsector
            return <i className="bi bi-stop-fill text-danger fs-5"></i>
        }


        const decimals = (isMicrosectorTime === false) ? 'SSS' : 'S';

        if (momentTime.hours() > 0) {
            return momentTime.format('H:mm:ss.' + decimals);
        }

        if (momentTime.minutes() > 0) {
            return momentTime.format('m:ss.' + decimals);
        }

        return momentTime.format('s.' + decimals);
    }


    const getPitCell = (lap, inPit) => {

        if (('pitStatuses' in lap) === false) {
            return null;
        }

        let pitIn = lap.pitStatuses.find((pitStatus) => pitStatus.inPit === true);
        let pitOut = lap.pitStatuses.find((pitStatus) => pitStatus.inPit === false);

        if (inPit === false && pitOut !== undefined) {
            return (
                <Badge bg="success" style={{ padding: 5 }}>
                    <i className="bi bi-box-arrow-right"></i>
                </Badge>
            );
        }

        if (inPit === true && pitIn !== undefined) {
            return (
                <Badge bg="primary" style={{ padding: 5 }}>
                    <i className="bi bi-box-arrow-in-right"></i>
                </Badge>
            );
        }

        return null;
    }


    const getTrackPartTime = (lap, sectorNumber, microSectorNumber) => {
        let trackPartTime = lap.trackTimes.find(trackTime => trackTime.sectorNumber == sectorNumber && trackTime.microSectorNumber == microSectorNumber);


        if (trackPartTime === undefined) {
            return null;
        }

        const millisecondsDigits = sectorNumber > 0 && microSectorNumber > 0;

        return formatTrackPartTime(trackPartTime.timeSet, millisecondsDigits);

    }


    const compareTrackPartTimes = (lap1, lap2, columnId, isDesc) => {
        // The sortType function should return 1 if rowA is larger,
        // and -1 if rowB is larger. react-table will take care of the rest.


        const targetPattern = /S(\d+)_M(\d+)/gm;
        const matches = targetPattern.exec(columnId);

        const targetSector = (matches === null) ? 0 : matches[1];
        const targetMicroSector = (matches === null) ? 0 : matches[2];


        const getOriginalTrackPartTime = (lap) => {
            const originalTrackPartTime = lap.original.trackTimes.find(trackTime => trackTime.sectorNumber == targetSector && trackTime.microSectorNumber == targetMicroSector);
            return (originalTrackPartTime ? originalTrackPartTime.timeSet : null);
        }

        let lap1OriginalTrackPartTime = getOriginalTrackPartTime(lap1);
        let lap2OriginalTrackPartTime = getOriginalTrackPartTime(lap2);



        if (lap1OriginalTrackPartTime == null) {
            return 1;
        }
        if (lap2OriginalTrackPartTime == null) {
            return -1;
        }

        return (lap1OriginalTrackPartTime >= lap2OriginalTrackPartTime) ? 1 : -1;
    }


    const getTyreCompoundCell = (lap, index) => {

        if (('stint' in lap) === false) {
            return null;
        }

        let tyreCompound = lap.stint.tyreCompound;
        let wasNewTyre = lap.stint.wasNewTyre;


        const tyreCompoundColors = {
            'SOFT': '#C30000',
            'MEDIUM': '#CDCD00',
            'HARD': '#A4A4A4',
            'INTERMEDIATE': '#29AF00',
            'WET': '#3946FF',
        }

        let color = ((tyreCompound in tyreCompoundColors === true) ? tyreCompoundColors[tyreCompound] : 'black');

        const symbol = (wasNewTyre === false) ? (<i className="bi bi-slash-circle-fill"></i>) : (<i className="bi bi-circle-fill"></i>);

        tyreCompound =

            (tyreCompound === 'UNKNOWN') ? null : (
                <b>
                    {tyreCompound[0]}
                </b>
            );


        return (
            <div style={{ color: color }}>
                {symbol} {tyreCompound}
            </div>
        );
    }




    const getTrackStatusPriority = (trackStatus) => {
        const priorities = {
            'VSCE': 1,
            'YELLOW': 2,
            'VSCD': 3,
            'SCD': 4,
            'RED': 5,
        };

        return (trackStatus in priorities ? priorities[trackStatus] : 0);
    }


    const getTrackStatusCell = (lap, index) => {

        if (('trackStatuses' in lap) === false) {
            return null;
        }


        let trackStatuses = lap.trackStatuses
            .filter((trackStatus) => trackStatus.trackStatus != 'CLEAR')

        if (trackStatuses.length <= 0) {
            return null;
        }

        let trackMainStatus = trackStatuses.map((trackStatus) => ({
            'trackStatus': trackStatus.trackStatus,
            'priority': getTrackStatusPriority(trackStatus.trackStatus),
        })).reduce((previous, current) =>
            (previous.priority > current.priority) ? previous : current
        );


        return <FlagSymbol type={trackMainStatus.trackStatus} />;
    }




    const addLap = (lap) => {

        let lapKey = {
            season: parseInt(lap.season),
            progressiveRoundNumber: parseInt(lap.progressiveRoundNumber),
            progressiveSessionNumber: parseInt(lap.progressiveSessionNumber),
            racingNumber: parseInt(lap.racingNumber),
            lapIndex: parseInt(lap.lapIndex),
        };

        /* checking if the lap has been selected before */
        let existingLocation = null;

        props.setSelectedLaps((selectedLaps) => {

            if (selectedLaps == null) selectedLaps = [];

            selectedLaps.forEach((element, index) => {
                let equal = true;
                for (const property in element) {
                    if (element[property] != lapKey[property]) {
                        equal = false;
                    }
                }
                if (equal) existingLocation = index;
            });

            if (existingLocation != null) {
                selectedLaps.splice(existingLocation, 1);

            } else {
                selectedLaps.push(lapKey);
            }


            window.localStorage.setItem('selectedLaps', JSON.stringify(selectedLaps));
            return selectedLaps;
        });

        props.setLaps((laps) => {
            let newLaps = [...laps];

            for (let i = 0; i < newLaps.length; i++) {
                if (newLaps[i].lapIndex === lap.lapIndex) {
                    if (existingLocation == null) newLaps[i].isSelected = true;
                    else delete newLaps[i].isSelected;
                    break;
                }
            }

            return newLaps;
        });

    }


    const buildAddLapButton = (lap, index) => {

        let isSelected = props.selectedLaps.find((selectedLap) =>
            selectedLap.season == lap.season
            && selectedLap.progressiveRoundNumber == lap.progressiveRoundNumber
            && selectedLap.progressiveSessionNumber == lap.progressiveSessionNumber
            && selectedLap.racingNumber == lap.racingNumber
            && selectedLap.lapIndex == lap.lapIndex
        ) !== undefined;


        return (
            <Button
                size="sm" style={{ borderRadius: '50%' }}
                onClick={() => addLap(lap)}

                // disabled={'isSelected' in lap && lap.isSelected === true}

                variant={isSelected ? 'success' : 'primary'}
            >
                {isSelected ? (<i className="bi bi-check"></i>) : (<i className="bi bi-plus"></i>)}
            </Button>
        );
    }


    const findMinValues = (laps) => {
        const lapTimes = laps.map(lap => getTrackPartTime(lap, 0, 0));


        const minLapTime = [...lapTimes].map(el => el == null ? el : parseFloat(el.replace(":", ""))).filter(el => el != null).sort((a, b) => a - b)[0];
        const bestLap = lapTimes.findIndex(el => (el == null ? el : parseFloat(el.replace(":", ""))) === minLapTime); // contains also null elements!  

        const bestMicrosectors = [];
        const bestSectors = [];


        // console.log(laps[10].trackTimes.find(el => el.sectorNumber == 1 && el.microSectorNumber == 1));

        for (const sectorNumber in microsectors) {
            const microsectorsCount = microsectors[sectorNumber];

            const sectorTimes = laps.map(lap => lap.trackTimes).map(part => part.filter(el => el.sectorNumber == sectorNumber && el.microSectorNumber == 0)).map(el => el[0] !== undefined ? el[0].timeSet : 60000);
            const minSectorTime = [...sectorTimes].sort()[0];
            const minSectorIndex = sectorTimes.findIndex(el => el === minSectorTime);
            bestSectors.push(minSectorIndex);

            for (let microsector = 1; microsector <= microsectorsCount; microsector++) {

                const microSectorTimes = laps.map(lap => lap.trackTimes).map(part => part.filter(el => el.sectorNumber == sectorNumber && el.microSectorNumber == microsector)).map(el => el[0] !== undefined ? el[0].timeSet : 60000);
                const minMicroSectorTime = [...microSectorTimes].sort()[0];
                const minMicroSectorIndex = microSectorTimes.findIndex(el => el === minMicroSectorTime);
                bestMicrosectors.push(minMicroSectorIndex);
            }

        }


        return {
            bestLap: bestLap,
            bestSectors: bestSectors,
            bestMicrosectors: bestMicrosectors
        };
    }


    const columns = React.useMemo(

        () => {
            let columns =
                [
                    {
                        id: 'generalData',
                        Header: 'Giro',
                        columns: [
                            {
                                id: 'addLap',
                                accessor: buildAddLapButton,
                                disableSortBy: true,
                            },

                            {
                                id: 'lapNumber',
                                Header: 'N',
                                accessor: (lap, index) => lap.lapNumber !== null ? (lap.lapNumber + 1) : null,
                                sortType: 'number',
                            },

                            {
                                id: 'lapTime',
                                Header: 'Tempo',
                                accessor: (lap, index) => {
                                    if (index == minValues.bestLap) {
                                        return (<b><b className='text-success'>{getTrackPartTime(lap, 0, 0)}</b></b>)
                                    }
                                    return (<b>{getTrackPartTime(lap, 0, 0)}</b>)
                                },
                                sortType: compareTrackPartTimes
                            },

                            {
                                id: 'tyreCompound',
                                Header: 'Mesc.',
                                accessor: getTyreCompoundCell,
                                disableSortBy: true,
                            },

                            {
                                id: 'tyreLaps',
                                Header: 'U.',
                                accessor: (lap, index) => 'stint' in lap ? lap.stint.tyreLaps : null,
                                sortType: 'number',
                            },

                            {
                                id: 'trackStatus',
                                Header: 'S.',
                                accessor: (lap, index) => getTrackStatusCell(lap, index),
                                disableSortBy: true,
                            },

                        ],
                    },
                ];

            if (props.showLinks === true) {

                columns[0].columns.unshift({
                    id: 'goToElement',
                    Header: '',
                    disableSortBy: true,
                    accessor: (lap, index) => {
                        let url = '/' + lap.season + '/' + lap.progressiveRoundNumber + '/' + lap.progressiveSessionNumber + '/laps/' + lap.racingNumber;
                        return (
                            <Link to={url}>
                                <Button
                                    size="sm" style={{ borderRadius: '50%' }}
                                    variant='outline-secondary'
                                >
                                    <i className="bi bi-box-arrow-up-right"></i>
                                </Button>
                            </Link>
                        )
                    },

                    // sortType: 'number',

                });

            }


            if (props.showColors === true) {
                columns[0].columns.unshift({
                    id: 'lineColor',
                    Header: '',
                    disableSortBy: true,
                    accessor: (lap, index) => <i className="bi bi-circle-fill" style={{ color: lap.lineColor }}></i>,
                });
            }


            if (props.showDriverInfo === true) {

                columns[0].columns.unshift({
                    id: 'driver',
                    Header: 'Pilota',
                    accessor: (lap, index) => lap.driverInfo.shortName,
                });

                columns[0].columns.unshift({
                    id: 'session',
                    Header: 'Sessione',
                    accessor: (lap, index) => lap.driverInfo.sessionName,
                });

                columns[0].columns.unshift({
                    id: 'event',
                    Header: 'Evento',
                    accessor: (lap, index) => reduceEventNameLength(lap.driverInfo.eventName),
                });

                columns[0].columns.unshift({
                    id: 'season',
                    Header: 'Anno',
                    accessor: (lap, index) => lap.season,
                });
            }


            if (microsectors === null) {
                return columns;
            }

            for (let s = 1; s <= 3; s++) {
                let microSectorColumns = [];


                if (s == 1) {
                    microSectorColumns.push({
                        id: 'pitOut',
                        Header: 'Pit',
                        accessor: (row, index) => getPitCell(row, false),
                        disableSortBy: true,
                    });
                }

                for (let m = 1; m <= microsectors[s]; m++) {

                    props.showMicroSectorsInTable && microSectorColumns.push({
                        id: 'S' + s + '_M' + m,
                        Header: 'M' + m,
                        accessor: (row, index) => {
                            if (index == minValues.bestMicrosectors[m - 1])
                                return (<b className='text-success'>{getTrackPartTime(row, s, m)}</b>);
                            return getTrackPartTime(row, s, m);

                        },
                        sortType: compareTrackPartTimes,
                    });

                }

                if (s == 3) {
                    microSectorColumns.push({
                        id: 'pitIn',
                        Header: 'Pit',
                        accessor: (row, index) => getPitCell(row, true),
                        disableSortBy: true,
                    });
                }


                microSectorColumns.push({
                    id: 'S' + s + '_all',
                    Header: 'Tempo',
                    accessor: (row, index) => {
                        if (index == minValues.bestSectors[s - 1])
                            return (<b className='text-success'><b>{getTrackPartTime(row, s, 0)}</b></b>)
                        return (<b>{getTrackPartTime(row, s, 0)}</b>)
                    },
                    sortType: compareTrackPartTimes,
                })

                columns.push({
                    id: 'S' + s,
                    Header: 'S' + s,
                    columns: microSectorColumns,
                });
            }


            return columns;
        }, [microsectors, props.showMicroSectorsInTable],
    );

    if (microsectors === null) {
        return <Loading />
    }

    const minValues = findMinValues(props.laps);

    // apply the stile on the cells


    // best lap
    //cells[minValues.bestLap].trackTimes[cells[minValues.bestLap].trackTimes.length - 1].timeSet;



    return <BasicTable columns={columns} data={props.laps} />;

}

export default LapsTable;
