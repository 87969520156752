class LZ77 {
    constructor(settings) {

        settings = settings || {};

        let referencePrefix = "`";
        let referenceIntBase = settings.referenceIntBase || 96;
        let referenceIntFloorCode = " ".charCodeAt(0);
        let referenceIntCeilCode = referenceIntFloorCode + referenceIntBase - 1;
        let maxStringDistance = Math.pow(referenceIntBase, 2) - 1;
        let minStringLength = settings.minStringLength || 5;
        let maxStringLength = Math.pow(referenceIntBase, 1) - 1 + minStringLength;
        let defaultWindowLength = settings.defaultWindowLength || 144;
        let maxWindowLength = maxStringDistance + minStringLength;

        let encodeReferenceInt = function (value, width) {
            if ((value >= 0) && (value < (Math.pow(referenceIntBase, width) - 1))) {
                let encoded = "";
                while (value > 0) {
                    encoded = (String.fromCharCode((value % referenceIntBase) + referenceIntFloorCode)) + encoded;
                    value = Math.floor(value / referenceIntBase);
                }
                let missingLength = width - encoded.length;
                for (let i = 0; i < missingLength; i++) {
                    encoded = String.fromCharCode(referenceIntFloorCode) + encoded;
                }
                return encoded;
            } else {
                throw "Reference int out of range: " + value + " (width = " + width + ")";
            }
        };

        let encodeReferenceLength = function (length) {
            return encodeReferenceInt(length - minStringLength, 1);
        };

        let decodeReferenceInt = function (data, width) {
            let value = 0;
            for (let i = 0; i < width; i++) {
                value *= referenceIntBase;
                let charCode = data.charCodeAt(i);
                if ((charCode >= referenceIntFloorCode) && (charCode <= referenceIntCeilCode)) {
                    value += charCode - referenceIntFloorCode;
                } else {
                    throw "Invalid char code in reference int: " + charCode;
                }
            }
            return value;
        };

        let decodeReferenceLength = function (data) {
            return decodeReferenceInt(data, 1) + minStringLength;
        };

        let lz77Compress = function (data, windowLength) {
            windowLength = windowLength || defaultWindowLength;
            if (windowLength > maxWindowLength) {
                throw "Window length too large";
            }
            let compressed = "";
            let pos = 0;
            let lastPos = data.length - minStringLength;
            while (pos < lastPos) {
                let searchStart = Math.max(pos - windowLength, 0);
                let matchLength = minStringLength;
                let foundMatch = false;
                let bestMatch = { distance: maxStringDistance, length: 0 };
                let newCompressed = null;
                while ((searchStart + matchLength) < pos) {
                    let isValidMatch = ((data.substr(searchStart, matchLength) == data.substr(pos, matchLength)) && (matchLength < maxStringLength));
                    if (isValidMatch) {
                        matchLength++;
                        foundMatch = true;
                    } else {
                        let realMatchLength = matchLength - 1;
                        if (foundMatch && (realMatchLength > bestMatch.length)) {
                            bestMatch.distance = pos - searchStart - realMatchLength;
                            bestMatch.length = realMatchLength;
                        }
                        matchLength = minStringLength;
                        searchStart++;
                        foundMatch = false;
                    }
                }
                if (bestMatch.length) {
                    newCompressed = referencePrefix + encodeReferenceInt(bestMatch.distance, 2) + encodeReferenceLength(bestMatch.length);
                    pos += bestMatch.length;
                } else {
                    if (data.charAt(pos) != referencePrefix) {
                        newCompressed = data.charAt(pos);
                    } else {
                        newCompressed = referencePrefix + referencePrefix;
                    }
                    pos++;
                }
                compressed += newCompressed;
            }
            return compressed + data.slice(pos).replace(/`/g, "``");
        };

        let lz77Decompress = function (data) {
            let decompressed = "";
            let pos = 0;
            while (pos < data.length) {
                let currentChar = data.charAt(pos);
                if (currentChar != referencePrefix) {
                    decompressed += currentChar;
                    pos++;
                } else {
                    let nextChar = data.charAt(pos + 1);
                    if (nextChar != referencePrefix) {
                        let distance = decodeReferenceInt(data.substr(pos + 1, 2), 2);
                        let length = decodeReferenceLength(data.charAt(pos + 3));
                        decompressed += decompressed.substr(decompressed.length - distance - length, length);
                        pos += minStringLength - 1;
                    } else {
                        decompressed += referencePrefix;
                        pos += 2;
                    }
                }
            }
            return decompressed;
        };

        this.compress = (data) => {
            if (!LZ77.isString(data))
                data = JSON.stringify(data);

            let compr = btoa(lz77Compress(data));
            return compr.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');
        };

        this.decompress = (data, json = false) => {
            data = (data + '===').slice(0, data.length + (4 - data.length % 4) % 4);
            data = data.replace(/-/g, '+').replace(/_/g, '/');

            let out = lz77Decompress(atob(data));
            if (!json)
                return out;
            return JSON.parse(out);
        };
    }

    static isString = (data) => {
        return (typeof data == 'string') || (data instanceof String);
    }

    static vectorRemoveKeys = (data, keys) => {
        if (this.isString(data)) data = JSON.parse(data);
        let inp = [];
        for (let lapKey in data) {
            let elem = [];
            for (let key in keys) {
                elem.push(data[lapKey][key]);
            };
            inp.push(elem);
        }
        return inp;
    };

    static vectorAddKeys = (data, keys) => {
        if (this.isString(data)) data = JSON.parse(data);
        let outv = [];
        for (let lapKey in data) {
            let elem = {};
            let c = 0;
            for (let key in keys) {
                elem[key] = data[lapKey][c++];
            }
            outv.push(elem);
        }
        return outv;
    };
}


export default LZ77;