import React from 'react';


function FlagSymbol(props) {

    switch (props.type) {
        case 'GREEN':
            return <i class="bi bi-stoplights-fill text-success"></i>

        case 'CLEAR':
            return <i className="bi bi-flag-fill text-success"></i>

        case 'BLUE':
            return <i className="bi bi-flag-fill text-primary"></i>

        case 'YELLOW':
            return <i className="bi bi-flag-fill text-warning"></i>

        case 'DOUBLE YELLOW':
            return (
                <>
                    <i className="bi bi-flag-fill text-warning"></i>
                    <i className="bi bi-flag-fill text-warning"></i>
                </>
            );

        case 'CHEQUERED':
            return <i className="bi bi-square-half"></i>

        case 'CE':
            return <i className="bi bi-person-circle"></i>

        case 'DRSE':
            return <b className="text-success">DRS</b>;

        case 'DRSD':
            return <b className="text-danger">DRS</b>;


        case 'VSCD':
        case 'VSCE':
            return <b className="text-warning">VSC</b>;

        case 'SCD':
        case 'SCITL':
            return <b className="text-warning">SC</b>;

        case 'RED':
            return <i className="bi bi-flag-fill text-danger"></i>
    }

    return null;
}


export default FlagSymbol;