import React from 'react';

import Config from '../../config.json';
import { Stack, Card, Button, Row, Col, Container, Modal, Form, ButtonGroup, OverlayTrigger, Tooltip, Badge, Spinner, ProgressBar } from 'react-bootstrap';
import fetchApi from '../../functions/fetchApi';
import Loading from '../Loading';
import ClickableCard from '../ClickableCard';
import BasicTable from '../BasicTable';
import WindowTable from '../WindowTable';

import LZ77 from '../../functions/LZ77';


import { useParams, Link, useLocation } from 'react-router-dom';
import { reduceEventNameLength } from '../../functions/reduceEventNameLength';
import { render } from '@testing-library/react';
import { reduceSessionNameLength } from '../../functions/reduceSessionNameLength';




function StatisticsTable(columns, statisticsData) {
    return (<div>
        <BasicTable
            tableClass={'table-striped table-bordered table-hover table-sm'}
            columns={columns}
            data={statisticsData}
            getUsePagination='true'
            rowsPerPage={[10, 25, 50, 100, 1000]}
            getCellProps={(cellInfo) => {

                if (cellInfo.column.parent.id == 'valuesData') {
                    if (cellInfo.value == 0)
                        return { className: 'table-danger' }
                    else
                        return { className: 'table-success' }
                }
                return { className: 'sticky-top' }
            }}
        />
    </div>);
}

// TODO: create a global function for that
const copyTextToClipboard = (text) => {
    var textArea = document.createElement("textarea");

    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = -9999999;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        document.execCommand('copy');
    } catch (err) {
        // TODO: check what to do if not able to copy text
    }

    document.body.removeChild(textArea);
}

function DataManagerPage(props) {

    const defaultRefreshInterval = 60;

    const [apiRequestDone, setApiRequestDone] = React.useState(false);
    const [statisticsData, setStatisticsData] = React.useState(null);
    const [currentDownloading, setCurrentDownloading] = React.useState(null);
    const [isUpdatingCurrentDownloading, setIsUpdatingCurrentDownloading] = React.useState(false);
    const [currentDownloadingComplete, setCurrentDownloadingComplete] = React.useState(false);
    const [performTableDataLoading, setPerformTableDataLoading] = React.useState(false);


    const [selectedYear, setSelectedYear] = React.useState(null);
    const [selectedRound, setSelectedRound] = React.useState(null);
    const [selectedSession, setSelectedSession] = React.useState(null);


    const [rowSelection, setRowSelection] = React.useState(null);

    const [show, setShow] = React.useState(false);
    const [fullDownloadEnabled, setFullDownloadEnabled] = React.useState(false);
    const [fullDownloadProgress, setFullDownloadProgress] = React.useState(0);

    const [showResult, setShowResult] = React.useState(undefined);
    const [isRequestLoading, setIsRequestLoading] = React.useState(false);

    const { season, progressiveRoundNumber, progressiveSessionNumber } = useParams();

    const currentLocation = useLocation().pathname;


    const [downloadedParts, setDownloadedParts] = React.useState(0);
    const [expectedLength, setExpectedLength] = React.useState(0);
    const [requestStarted, setRequestStarted] = React.useState(false);

    const [currentDownloadingTimer, setCurrentDownloadingTimer] = React.useState(defaultRefreshInterval);

    const [isDownloadingPDF, setIsDownloadingPDF] = React.useState(false);
    const [showPDFarea, setShowPDFarea] = React.useState(false);
    const [pdfResult, setPdfResult] = React.useState(undefined);


    React.useEffect(() => {
        if (show === false) {
            setFullDownloadProgress(0);
            setFullDownloadEnabled(false);
        }
    }, [show]);


    function updateCurrentDownloading() {
        setIsUpdatingCurrentDownloading(true);
        const currentDownloadingApiUrl = Config.apiEndpoint + '/statistics/currentDownloading';
        return fetchApi(currentDownloadingApiUrl, (data) => {
            setCurrentDownloading([...data]);
            setIsUpdatingCurrentDownloading(false);
            setCurrentDownloadingComplete(true);
        });
    }


    function performFullDataUpdate() {
        const apiUrl = Config.apiEndpoint + '/statistics/data';

        if (!currentDownloadingComplete)
            updateCurrentDownloading();


        if (apiRequestDone === false && !requestStarted && performTableDataLoading) {
            // perform the request only once
            setRequestStarted(true);

            function handleEvent(e) {

                const parts = e.currentTarget.response != undefined ? /^(\d+)(\.+)(\{*.*)/.exec(e.currentTarget.response) : undefined;

                if (e.type == 'progress') {
                    if (parts[1] != expectedLength) setExpectedLength(parts[1]);
                    setDownloadedParts(parts[2].length);
                } else if (e.type == 'loadend') {
                    const response = JSON.parse(parts[3]);
                    setStatisticsData([...response]);
                    setApiRequestDone(() => true);
                }
            }


            function addListeners(xhr) {
                xhr.addEventListener('loadstart', handleEvent);
                xhr.addEventListener('load', handleEvent);
                xhr.addEventListener('loadend', handleEvent);
                xhr.addEventListener('progress', handleEvent);
                xhr.addEventListener('error', handleEvent);
                xhr.addEventListener('abort', handleEvent);
            }


            updateCurrentDownloading().then(() => {
                const xhr = new XMLHttpRequest();
                addListeners(xhr);
                xhr.open("GET", apiUrl);
                xhr.setRequestHeader('X-loginToken', window.localStorage.getItem('loginToken'));
                xhr.send();
            });



        }
    }

    React.useEffect(async () => {
        performFullDataUpdate()
    }, [apiRequestDone, requestStarted, performTableDataLoading]);



    React.useEffect(() => {
        if (currentDownloadingTimer <= 0) {
            updateCurrentDownloading();
            setCurrentDownloadingTimer(defaultRefreshInterval);
        }

        const id = setInterval(() => {
            setCurrentDownloadingTimer(currentDownloadingTimer - 1);
        }, 1000);
        return () => clearInterval(id);
    }, [currentDownloadingTimer]);



    if (apiRequestDone === false && performTableDataLoading || !currentDownloadingComplete) {
        /*
        return <>
            <Loading />
            {apiRequestDone === false && performTableDataLoading && (
                <div className="text-center">
                    Ottenimento dei dati<br />
                    {downloadedParts}/{expectedLength}
                </div>
            )}
        </>;
        */
    }

    let columns = [
        {
            id: 'generalData',
            Header: 'Giro',
            columns: [
                {
                    id: 'goToElement',
                    Header: '',
                    disableSortBy: true,
                    accessor: (event, index) => {
                        let url = '/' + event.season + '/' + event.round + '/' + event.session;
                        return (
                            <Link to={url}>
                                <span className='text-secondary'>
                                    <i className={"bi bi-box-arrow-up-right"} onClick={() => {
                                        // setShow(true);
                                        // setRowSelection(index);
                                    }}></i>
                                </span>
                            </Link>
                        )
                    },

                    // sortType: 'number',

                },
                {
                    id: 'season',
                    Header: 'Stagione',
                    accessor: elem => elem['season'],
                    aggregate: 'count',
                }, {
                    id: 'round',
                    Header: 'Evento',
                    accessor: elem => elem['round'],
                },
                {
                    id: 'session',
                    Header: 'Session',
                    accessor: elem => elem['session'],
                },
                {
                    id: 'selection',
                    Header: '',
                    accessor: (statistic, index) => {
                        return (
                            <span className={((rowSelection == index) ? 'text-success' : 'text-danger')}>
                                <i className={"bi bi-cloud-arrow-down-fill"} onClick={() => {
                                    setShow(true);
                                    setRowSelection(index);
                                }}></i>
                            </span>

                        );
                    },
                    disableSortBy: true,
                }

            ],
        }, {
            id: 'valuesData',
            Header: 'Dati',

            columns: [],

        },
    ];



    let seasons = () => {
        let vector = [];
        let last = -1;
        statisticsData.forEach(element => {
            if (element['season'] != last) {
                last = element['season'];
                vector.push(last);
            }
        });
        return vector;
    }


    let events = () => {
        let vector = [];
        let last = -1;
        // get information about the events
        statisticsData.forEach(element => {
            if (element['season'] == selectedYear && element['round'] != last) {
                last = element['round'];
                vector.push(element);
            }
        });
        return vector;
    }

    let sessions = () => {
        let vector = [];
        let last = -1;
        // get information about the events

        statisticsData.forEach(element => {
            if (element['season'] == selectedYear && element['round'] == selectedRound && element['session'] != last) {
                last = element['session'];
                vector.push(element);
            }
        });
        return vector;
    }

    const updatePageUrl = (season, round, session) => {
        // check if we are on the real data page
        if (!/^\/data\//.test(window.location.pathname)) return;

        let url = '/data';
        if (season != undefined && season != 'all') {
            url += '/' + season;
            if (round != undefined && round != 'all') {
                url += '/' + round;
                if (session != undefined && session != null && session != 'all') {
                    url += '/' + session;
                }
            }
        }
        window.history.pushState(undefined, undefined, url);
    }



    const selection = () => {
        let yearVector = seasons().reverse();

        // console.log(selectedYear, selectedRound, selectedSession);

        //if (season === undefined && progressiveRoundNumber === undefined && progressiveSessionNumber === undefined) {
        if (selectedYear === null && selectedRound === null && selectedSession === null) {
            // check a match in the url
            if (/^\/(\d{4})\/(\d{1,2})\/(\d{1,2})/.test(currentLocation)) {
                const parts = /^\/(\d{4})\/(\d{1,2})\/(\d{1,2})/.exec(currentLocation);
                setSelectedYear(parts[1]);
                setSelectedRound(parts[2]);
                setSelectedSession(parts[3]);
            } else if (/^\/(\d{4})\/(\d{1,2})/.test(currentLocation)) {
                const parts = /^\/(\d{4})\/(\d{1,2})/.exec(currentLocation);
                setSelectedYear(parts[1]);
                setSelectedRound(parts[2]);
                setSelectedSession(null);
            } else if (/^\/(\d{4})/.test(currentLocation)) {
                const parts = /^\/(\d{4})/.exec(currentLocation);
                setSelectedYear(parts[1]);
                setSelectedRound(null);
                setSelectedSession(null);
            } else {
                // set the current year as the default value
                setSelectedYear(yearVector[0]);
                setSelectedRound(null);
                setSelectedSession(null);
            }
        }


        if (selectedYear === null) {
            if (season !== undefined) setSelectedYear(season);
        }

        if (selectedRound === null) {
            if (progressiveRoundNumber !== undefined) setSelectedRound(progressiveRoundNumber);
        }
        if (selectedSession === null) {
            if (progressiveSessionNumber !== undefined) setSelectedSession(progressiveSessionNumber);
        }


        const seasonSelection = (
            <Form.Select onChange={e => {
                const yearNumber = e.target.value;
                updatePageUrl(yearNumber, selectedRound);
                setSelectedYear(yearNumber)
            }}>

                <option key='all' value='all' >
                    Tutte le stagioni
                </option>

                {yearVector.map((year, index) => {
                    // TODO: write a better function to select the first year first.
                    if (year == selectedYear) {
                        return (
                            <option key={year} value={year} selected>
                                Stagione {year}
                            </option>
                        );
                    }
                    return (
                        <option key={year} value={year}>
                            Stagione {year}
                        </option>
                    );
                })}
            </Form.Select>
        );


        const roundSelection = (
            <Form.Select onChange={e => {
                const roundNumber = e.target.value;
                updatePageUrl(selectedYear, roundNumber, selectedSession);
                setSelectedRound(roundNumber)
            }}>
                <option key='all' value='all' >
                    Tutti i round
                </option>

                {events().map((event, index) => {
                    // TODO: write a better function to select the first year first.
                    if (event['round'] == selectedRound) {
                        return (
                            <option key={'event' + index} value={index} selected>
                                {index} • {reduceEventNameLength(event.name)}
                            </option>
                        );
                    }
                    return (
                        <option key={'event' + index} value={index}>
                            {index} • {reduceEventNameLength(event.name)}
                        </option>
                    );
                })}
            </Form.Select>
        );

        const sessionSelection = (
            <Form.Select onChange={e => {
                const sessionNumber = e.target.value;
                updatePageUrl(selectedYear, selectedRound, sessionNumber);
                setSelectedSession(sessionNumber);
            }}>
                <option key='all' value='all' >
                    Tutte le sessioni
                </option>

                {sessions().map((session) => {
                    const index = session['session'];

                    // TODO: write a better function to select the first year first.
                    if (session['session'] == selectedSession) {
                        return (
                            <option key={'session' + index} value={index} selected>
                                {index} • {session.sessionName}
                            </option>
                        );
                    }
                    return (
                        <option key={'event' + index} value={index}>
                            {index} • {session.sessionName}
                        </option>
                    );
                })}
            </Form.Select>
        );


        return (
            <>
                {seasonSelection}
                {roundSelection}
                {sessionSelection}
            </>
        );
    }




    let variableValues = {
        drivers: {
            name: 'Piloti',
            path: {
                get: 'drivers',
                post: 'drivers'
            }
        },
        gps: {
            name: 'GPS',
            path: {
                post: 'gps'
            }
        },
        history: {
            name: 'History',
            path: {
                post: 'history'
            }
        },
        messages: {
            name: 'Messaggi',
            path: {
                post: 'raceControlMessages'
            }
        },
        pits: {
            name: 'Pits',
            path: {
                post: 'pitStatuses'
            }
        },
        speed: {
            name: 'Velocità',
            path: {
                post: 'speeds'
            }
        },
        stints: {
            name: 'Stints',
            path: {
                post: 'stints'
            }
        },
        telemetry: {
            name: 'Telemetria',
            path: {
                post: 'telemetry'
            }
        },
        timeRemaining: {
            name: 'Tempo Rimanente',
            path: {
                post: 'timeRemaining'
            }
        },
        timeSync: {
            name: 'Time Sync',
            path: {
                post: 'timeSync'
            }
        },
        trackStatuses: {
            name: 'Stato pista',
            path: {
                post: 'trackStatuses'
            }
        },
        weather: {
            name: 'Meteo',
            path: {
                post: 'weather'
            }
        },
        laps: {
            name: 'Giri',
            path: {
                post: 'raceLaps'
            }
        },
        sectors: {
            name: 'Settori',
            path: {
                post: 'sectorLapTimes'
            }
        },
        microsectors: {
            name: 'Microsettori',
            path: {
                post: 'microsectorTimes'
            }
        }
    };


    if (columns[1]['columns'].length == 0) {
        for (const key in variableValues) {
            columns[1]['columns'].push({
                id: key,
                Header: variableValues[key]['name'],
                accessor: elem => elem[key],
                disableSortBy: true,
            });
        }
    }

    const eventValues = () => {
        const currentSelection = statisticsData.filter((event, index) => {
            return ((selectedYear == 'all' || selectedYear == null || event['season'] == selectedYear) &&
                (selectedRound == 'all' || selectedRound == null || event['round'] == selectedRound) &&
                (selectedSession == 'all' || selectedSession == null || event['session'] == selectedSession)
            );
        })[rowSelection];



        if (currentSelection == null || currentSelection == undefined) return null;

        const innerColumns = [{
            id: 'generalData',
            Header: 'Dati disponibili',
            columns: [
                {
                    id: 'key',
                    Header: 'Chiave',
                    accessor: elem => elem['key'],
                }, {
                    id: 'value',
                    Header: 'Valore',
                    accessor: elem => elem['value'],
                },
                {
                    id: 'update',
                    Header: 'Copia comando',
                    accessor: elem => elem['update'],
                },
                {
                    id: 'run',
                    Header: 'Esegui comando',
                    accessor: elem => elem['run'],
                },
            ],
        }];

        const variants = {
            get: 'success',
            post: 'warning',
            delete: 'danger'
        };
        let v = [];
        for (const variableName of Object.keys(variableValues)) {
            const update = () => {
                const varElem = variableValues[variableName];
                if (varElem['path'] == undefined) return null;
                let elements = [];

                for (const method of Object.keys(varElem['path'])) {
                    const path = 'curl -X ' + method.toUpperCase() + ' -H "x-logintoken: ' + window.localStorage.getItem('loginToken') + '" ' +
                        Config.apiEndpoint + '/' +
                        currentSelection['season'] + '/' + currentSelection['round'] + '/' + currentSelection['session'] + '/' +
                        varElem['path'][method];
                    elements.push(
                        <OverlayTrigger
                            placement='top'
                            trigger='click'
                            overlay={<Tooltip>Copiato!</Tooltip>}
                            rootClose={true}
                        >
                            <Button className='btn-sm mr-1' variant={'outline-' + variants[method.toLowerCase()]} onClick={() => {
                                copyTextToClipboard(path);
                                setTimeout(() => {
                                    document.body.click();
                                }, 2500);
                            }}>
                                {method.toUpperCase()}
                            </Button>
                        </OverlayTrigger>
                    );

                }
                return elements;
            }

            const runCommand = () => {
                const varElem = variableValues[variableName];
                if (varElem['path'] == undefined) return null;
                let elements = [];

                for (const method of Object.keys(varElem['path'])) {
                    const path = Config.apiEndpoint + '/' +
                        currentSelection['season'] + '/' + currentSelection['round'] + '/' + currentSelection['session'] + '/' +
                        varElem['path'][method];
                    elements.push(

                        <Button className='btn-sm mr-1' variant={'outline-' + variants[method.toLowerCase()]} onClick={() => {
                            setIsRequestLoading(true);
                            fetchApi(path, (data) => {
                                const value = JSON.stringify(data, null, 2);
                                // setApiRequestDone(() => false);
                                // makeApiRequest();
                                setShowResult(value);
                                setIsRequestLoading(false);
                            }, method.toUpperCase());
                        }}>
                            {isRequestLoading ? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : method.toUpperCase()}
                        </Button>

                    );
                }
                return elements;
            }
            v.push({
                key: variableName,
                value: currentSelection[variableName],
                update: (
                    <ButtonGroup aria-label="Basic example">
                        {update()}
                    </ButtonGroup>
                ),
                run: (
                    <ButtonGroup aria-label="Basic example">
                        {runCommand()}
                    </ButtonGroup>
                )
            });
        }


        return (
            <>
                <Row>
                    <Col>
                        <ul>
                            <li>Percorso: {currentSelection['season'] + '/' + currentSelection['round'] + '/' + currentSelection['session']}</li>
                            <li>Data: {currentSelection['data']}</li>
                        </ul>
                    </Col>
                </Row>

                <Row className='mt-2'>
                    <Col>
                        <Card>
                            <Card.Header>Aggiorna dati</Card.Header>
                            <Card.Body>
                                Aggiorna tutti gli elementi di tale evento:<br />
                                <OverlayTrigger
                                    placement='top'
                                    trigger='click'
                                    overlay={<Tooltip>Copiato!</Tooltip>}
                                    rootClose={true}
                                >
                                    <Button variant="secondary" className="btn-sm" onClick={() => {
                                        const date = currentSelection['season'] + '.' + currentSelection['round'] + '.' + currentSelection['session'];
                                        const token = window.localStorage.getItem('loginToken');
                                        const message = date + ' ' + token;
                                        copyTextToClipboard(message);
                                        setTimeout(() => {
                                            document.body.click();
                                        }, 2500);
                                        setTimeout(() => {
                                            copyTextToClipboard(" ");
                                        }, 15 * 1000);
                                    }}>
                                        <i className="bi bi-clipboard"></i> Copia comando
                                    </Button>
                                </OverlayTrigger>



                                <Button variant="outline-danger" className="btn-sm" onClick={() => {
                                    setFullDownloadEnabled(!fullDownloadEnabled);
                                }}>
                                    <i className="bi bi-gear-wide-connected"></i> Esegui ora
                                </Button>

                                <Card border='danger' style={{ display: fullDownloadEnabled ? 'block' : 'none' }}>
                                    <Card.Body>
                                        <Card.Title>Scarica tutto</Card.Title>
                                        <Card.Text>
                                            L'operazione richiede alcuni secondi. Non chiudere la pagina fino a quando l'operazione non sarà conclusa!

                                            <Button variant="outline-danger" onClick={() => {
                                                const apiList = ["drivers", "history", "raceControlMessages", "pitStatuses", "speeds", "stints", "timeRemaining", "timeSync", "trackStatuses", "weather", "raceLaps", "sectorLapTimes", "microsectorTimes", "gps", "telemetry"];
                                                function performOneDownload(index) {
                                                    if (index >= apiList.length) {
                                                        setFullDownloadProgress(index + 1);
                                                        return;
                                                    }

                                                    const url = Config.apiEndpoint + '/' + currentSelection['season'] + '/' + currentSelection['round'] + '/' + currentSelection['session'] + '/' + apiList[index];

                                                    return fetchApi(url, (data) => {
                                                        setFullDownloadProgress(index);
                                                        return performOneDownload(index + 1);
                                                    }, 'PUT');

                                                }

                                                performOneDownload(0);
                                            }}>
                                                Inizia
                                            </Button>

                                            <ProgressBar animated now={fullDownloadProgress / 15 * 100} />


                                        </Card.Text>
                                    </Card.Body>
                                </Card>

                                <br />

                                <i className="bi bi-info-circle"></i> Tale comando contiene due parametri: il codice identificativo dell'evento e il tuo token personale. Usare queste informazioni per scaricare i dati richiesti al di fuori di questa pagina. La clipboard viene svuotata dopo 15 secondi.

                                <hr />

                                Controlla la disponibilità dei dati accedento tramite il tuo dispositivo a <a href={currentSelection['archiveStatus']} target='blank'>questo</a> url.


                            </Card.Body>

                        </Card>

                    </Col>
                </Row>

                <Row className='mt-4'>
                    <Col>
                        <BasicTable
                            tableClass={'table-striped table-bordered table-hover table-sm'}
                            columns={innerColumns}
                            data={v}
                            getUsePagination='false'
                            rowsPerPage={[10, 25, 50, 100, 1000]}
                            getCellProps={(cellInfo) => {
                                if (cellInfo.column.id == 'value') {
                                    return (cellInfo.value == 0) ? { className: 'table-danger' } : { className: 'table-success' }
                                }
                                return { className: 'sticky-top' }
                            }}
                        />

                    </Col>
                </Row>
            </>
        );
    }


    const modal = () => (
        <Modal show={show} size="lg" onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Consultazione evento
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {showResult !== undefined ? (
                    <Form.Control
                        as="textarea"
                        disabled={(true)}
                        /*
                        onChange={(e) => {
                            e.target.style.height = '';
                            e.target.style.height = (e.target.scrollHeight + 5) + 'px';
                        }}
                        */
                        rows='10'
                        value={showResult}
                    />
                ) : ''}
                {eventValues()}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Chiudi
                </Button>
            </Modal.Footer>
        </Modal>
    );



    const currentDownloadingInformation = () => {

        if (currentDownloading.length == 0) return;

        return <Row className='mb-4 justify-content-center'>
            <Col className='col-lg-6'>
                <Card>
                    <Card.Header className="card-header d-flex justify-content-between align-items-center">

                        Aggiornamenti recenti ({currentDownloadingTimer})

                        <Button onClick={() => updateCurrentDownloading() && setCurrentDownloadingTimer(defaultRefreshInterval)} className='btn-sm' variant='outline-secondary'>
                            {isUpdatingCurrentDownloading ? (<Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />) : (<i className="bi bi-arrow-clockwise"></i>)}
                        </Button>

                    </Card.Header>
                    <Card.Body>
                        {
                            currentDownloading.sort((a, b) => b.content.beginning - a.content.beginning).map(element => {
                                const name = element.name;
                                const current = element.content.count ?? null;
                                const maximum = element.content.max ?? null;
                                const percentage = (current != null && maximum != null) ? Math.round(100 * current / maximum) : null;
                                const progress = (current != null && maximum != null) ? (maximum - current > 100 ? current + '/' + maximum + ' (' + percentage + '%)' : current + '/' + maximum + ' (100%)') : '';

                                const color = (percentage == null) ? 'danger' : (maximum - current > 100 ? 'warning' : 'success');
                                const icon = (<i className={"bi bi-circle-fill text-" + color + " small"}></i>);
                                return (
                                    <li>{icon} {name} {progress}</li>
                                );
                            })
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>;
    }


    const pdfYear = () => season == undefined ? (new Date().getFullYear()) : season;

    const pdfDownload = (year) => {
        setIsDownloadingPDF(true);
        const currentDownloadingApiUrl = Config.apiEndpoint + '/' + year + '/pdf';
        return fetchApi(currentDownloadingApiUrl, (data) => {
            setPdfResult(JSON.stringify(data, null, 4));
            setShowPDFarea(true);
            setIsDownloadingPDF(false);
        }, 'PUT');
    }

    return (
        <>
            <Row className='mb-4 justify-content-center'>
                <Col className='col col-lg-6 align-self-center'>
                    <Card>
                        <Card.Header>
                            <i className="bi bi-person-check"></i> Utente amministratore
                        </Card.Header>
                        <Card.Body>
                            Da questa area puoi copiare il tuo codice personale per effettuare richieste direttamente al backend o utilizzare tool da linea di comando.
                            Ricorda che il token scade automaticamente per inattività, e che puoi effettuare il logout dall'interfaccia web per disattivarlo immediatamente.


                            <div className="d-grid gap-2">
                                <OverlayTrigger
                                    placement='top'
                                    trigger='click'
                                    overlay={<Tooltip>Copiato!</Tooltip>}
                                    rootClose={true}
                                >
                                    <Button variant="outline-secondary" className="btn-sm" onClick={() => {
                                        const token = window.localStorage.getItem('loginToken');
                                        copyTextToClipboard(token);
                                        setTimeout(() => {
                                            document.body.click();
                                        }, 2500);
                                        setTimeout(() => {
                                            copyTextToClipboard(" ");
                                        }, 5 * 1000);
                                    }}>
                                        <i className="bi bi-clipboard"></i> Copia token personale
                                    </Button>
                                </OverlayTrigger>
                            </div>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>


            {/* Mostro progresso mentre carico la tabella */}
            {apiRequestDone === false && performTableDataLoading && (
                <>
                    <Loading />
                    <div className="text-center">
                        Ottenimento dei dati<br />
                        {downloadedParts}/{expectedLength}
                    </div>
                </>
            )}




            {showPDFarea &&
                <Row className='mt-2'>
                    <Col>
                        <Card>
                            <Card.Header className="card-header d-flex justify-content-between align-items-center">
                                Download PDF
                                <Button onClick={() => setShowPDFarea(false)} className='btn-sm' variant='outline-secondary'>
                                    <i className="bi bi-x-lg"></i>
                                </Button>
                            </Card.Header>
                            <Card.Body>
                                <Form.Control
                                    as="textarea"
                                    disabled={(true)}
                                    rows='8'
                                    value={pdfResult}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            }


            {currentDownloadingComplete && currentDownloadingInformation()}


            {(apiRequestDone || !requestStarted) &&
                <Row className='mb-4 justify-content-center'>
                    <Col className='col col-lg-3 align-self-center'>
                        <div className="d-grid gap-2">
                            <Button variant="warning" onClick={() => {
                                setPerformTableDataLoading(true);
                                setDownloadedParts(0);
                                setExpectedLength(0);
                                setRequestStarted(0);
                                setRequestStarted(false);
                                setApiRequestDone(false);
                                performFullDataUpdate();
                            }}>
                                {
                                    apiRequestDone && (<>
                                        <i className="bi bi-arrow-clockwise"></i> Ricarica tabella eventi
                                    </>) || (<>
                                        <i className="bi bi-table"></i> Carica tabella eventi
                                    </>)
                                }
                            </Button>
                        </div>
                    </Col>
                    <Col className='col col-lg-3 align-self-center'>
                        <div className="d-grid gap-2">
                            <Button variant="success" onClick={() => {
                                pdfDownload(pdfYear());
                            }}>
                                {isDownloadingPDF ? (<>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </>) : (<>
                                    <i className="bi bi-filetype-pdf"></i> Scarica PDF {pdfYear()}
                                </>)}

                            </Button>
                        </div>

                    </Col>
                </Row>
            }



            {apiRequestDone && selection()}
            {apiRequestDone && modal()}


            {apiRequestDone && StatisticsTable(columns,
                statisticsData.filter(event => {
                    if (selectedYear == 'all' || selectedYear == null) return true;
                    if (event['season'] == selectedYear) {
                        return (selectedRound == 'all' || selectedRound == null || event['round'] == selectedRound) && (selectedSession == null || selectedSession == 'all' || event['session'] == selectedSession)
                    }
                }))
            }

        </>
    );

}

export default DataManagerPage;