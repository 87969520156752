import React from 'react';

import Config from '../../config.json';
import LapsTable from '../LapsTable';

import fetchApi from '../../functions/fetchApi';
import Loading from '../Loading';


import { Badge, Button, Form, Card, Row } from 'react-bootstrap';


import { reduceEventNameLength } from '../../functions/reduceEventNameLength';
import { reduceSessionNameLength } from '../../functions/reduceSessionNameLength';

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useParams, Link } from 'react-router-dom';

function LapsPage(props) {
    let { season, progressiveRoundNumber, progressiveSessionNumber, racingNumber } = useParams();

    const [laps, setLaps] = React.useState(null);
    const [driverInfo, setDriverInfo] = React.useState(null);



    // const [sectorsInfo, setSectorsInfo] = React.useState(null);

    const { trackPageView, trackEvent } = useMatomo()
    React.useEffect(() => {
        trackPageView();
    }, [])


    // read the current value in the local storage
    const storageMicroSectors = window.localStorage.getItem('showMicroSectorsInTable') === null || window.localStorage.getItem('showMicroSectorsInTable') == 'true';
    const [showMicroSectorsInTable, setShowMicroSectorsInTable] = React.useState(storageMicroSectors);


    React.useEffect(() => {

        // laps
        fetchApi(Config.apiEndpoint + '/' + season + '/' + progressiveRoundNumber + '/' + progressiveSessionNumber + '/driverLapsWithInfo/' + racingNumber,
            (laps) => {
                let savedLaps = JSON.parse(window.localStorage.getItem('selectedLaps'));

                for (let i = 0; i < laps.length; i++) {
                    laps[i].season = season;
                    laps[i].progressiveRoundNumber = progressiveRoundNumber;
                    laps[i].progressiveSessionNumber = progressiveSessionNumber;
                    laps[i].racingNumber = racingNumber;
                }

                // console.log(savedLaps);

                if (savedLaps == undefined || savedLaps == null) savedLaps = [];

                let selectedLapNumbers = savedLaps.filter(
                    selectedLap =>
                        selectedLap.season == season && selectedLap.progressiveRoundNumber == progressiveRoundNumber && selectedLap.progressiveSessionNumber == progressiveSessionNumber && selectedLap.racingNumber == racingNumber
                ).map(selectedLap => selectedLap.lapNumber);

                for (let i = 0; i < laps.length; i++) {
                    if (selectedLapNumbers.includes(laps[i].lapNumber)) {
                        laps[i].isSelected = true;
                    }
                }

                setLaps(laps);
            }
        );


        // driver info
        fetchApi(Config.apiEndpoint + '/' + season + '/' + progressiveRoundNumber + '/' + progressiveSessionNumber + '/drivers/' + racingNumber + '/info',

            (driverInfo) => {
                props.setPath((oldPath) =>
                    [{
                        text: <i className="bi bi-house-door"></i>,
                        url: '/',
                    }, {
                        text: season,
                        url: '/' + season,
                    }, {
                        text: reduceEventNameLength(driverInfo.eventName),
                        url: '/' + season + '/' + progressiveRoundNumber,
                    }, {
                        text: 'Sessioni',
                        url: '/' + season + '/' + progressiveRoundNumber + '/sessions',
                    },
                    {
                        text: reduceSessionNameLength(driverInfo.sessionName),
                        url: '/' + season + '/' + progressiveRoundNumber + '/' + progressiveSessionNumber
                    },
                    {
                        text: 'Giri',
                        url: '/' + season + '/' + progressiveRoundNumber + '/' + progressiveSessionNumber + '/laps',
                    }, {
                        text: driverInfo.shortName,
                        url: '/' + season + '/' + progressiveRoundNumber + '/' + progressiveSessionNumber + '/laps/' + racingNumber,
                        disabled: true,
                    }]
                );

                setDriverInfo(driverInfo);
            });


        // micro sector number
        /*
        fetchApi(Config.apiEndpoint + '/' + season + '/' + progressiveRoundNumber + '/' + progressiveSessionNumber + '/sectorsInfo',
            (sectorsInfo) => {

                sectorsInfo = sectorsInfo.sort(
                    (a, b) => a.sectorNumber <= b.sectorNumber ? -1 : 1
                );

                setSectorsInfo(sectorsInfo);
            }
        );
        */


    }, []);


    if (laps === null || driverInfo === null) {
        return <Loading />
    }


    return <>
        <LapsTable laps={laps} setLaps={setLaps} selectedLaps={props.selectedLaps} setSelectedLaps={props.setSelectedLaps} showLinks={false} showMicroSectorsInTable={showMicroSectorsInTable} />

        <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Visualizza i tempi dei microsettori" checked={showMicroSectorsInTable} onChange={() => {
                window.localStorage.setItem('showMicroSectorsInTable', !showMicroSectorsInTable);
                setShowMicroSectorsInTable(!showMicroSectorsInTable);
            }} />
        </Form.Group>

    </>
}

export default LapsPage;
