import React from 'react';

import Config from '../../config.json';
import { Stack, Card } from 'react-bootstrap';
import fetchApi from '../../functions/fetchApi';
import Loading from '../Loading';
import ClickableCard from '../ClickableCard';
import MessageBox from '../MessageBox';

import { reduceEventNameLength } from '../../functions/reduceEventNameLength';
import { reduceSessionNameLength } from '../../functions/reduceSessionNameLength';

import { useParams, Link } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react'


function DriversPage(props) {
    let { season, progressiveRoundNumber, progressiveSessionNumber } = useParams();

    const [drivers, setDrivers] = React.useState(null);
    const [sessionInfo, setSessionInfo] = React.useState(null);

    const { trackPageView, trackEvent } = useMatomo()
    React.useEffect(() => {
        trackPageView();
    }, [])


    React.useEffect(() => {
        fetchApi(Config.apiEndpoint + '/' + season + '/' + progressiveRoundNumber + '/' + progressiveSessionNumber + '/drivers', (drivers) => setDrivers(drivers));

        fetchApi(Config.apiEndpoint + '/' + season + '/' + progressiveRoundNumber + '/' + progressiveSessionNumber + '/info',

            (sessionInfo) => {
                props.setPath((oldPath) =>
                    [
                        {
                            text: <i className="bi bi-house-door"></i>,
                            url: '/',
                        },
                        {
                            text: season,
                            url: '/' + season,
                        }, {
                            text: reduceEventNameLength(sessionInfo.eventName),
                            url: '/' + season + '/' + progressiveRoundNumber,
                        }, {
                            text: 'Sessioni',
                            url: '/' + season + '/' + progressiveRoundNumber + '/sessions',
                        },
                        {
                            text: reduceSessionNameLength(sessionInfo.sessionName),
                            url: '/' + season + '/' + progressiveRoundNumber + '/' + progressiveSessionNumber,
                        }, {
                            text: 'Giri',
                            url: '/' + season + '/' + progressiveRoundNumber + '/' + progressiveSessionNumber + '/laps',
                            disabled: true,
                        }]
                );

                setSessionInfo(sessionInfo);
            });

    }, []);


    if (drivers === null || sessionInfo === null) {
        return <Loading />
    }

    if (drivers.length == 0) {
        return (
            <MessageBox
                text={
                    <span>I dati di questa sessione non sono al momento dispoinibili.</span>
                }
                variant='warning'
                rightColumn={(<i className="bi bi-info-circle-fill"></i>)}

            />
        )
    }

    return (
        <Stack direction="vertical" gap={4}>
            {
                drivers.map((driver, index) =>

                    <Link to={'/' + season + '/' + progressiveRoundNumber + '/' + progressiveSessionNumber + '/laps/' + driver.racingNumber} key={driver.racingNumber} className="col clickable-card">
                        <ClickableCard
                            title={driver.fullName}

                            subtitles={[
                                driver.teamName,
                                driver.racingNumber,
                            ]}

                            color={
                                driver.teamColour !== null ? (
                                    '#' + (driver.teamColour === 'FFFFFF' ? '000000' : driver.teamColour)
                                ) : null
                            }
                        />
                    </Link>

                )
            }
        </Stack>
    );
}


export default DriversPage;