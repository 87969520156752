async function fetchApi(endpoint, callback, method = 'GET') {

	let headers = {

	};

	const requestOptions = {
		method: method,
		headers: { 'X-loginToken': window.localStorage.getItem('loginToken') }
	};

	let result = await fetch(endpoint, requestOptions)
		.then(
			(response) => response.json()
		)
		.then(
			(result) => result,

			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.

			(error) => {
				// GESTIRE
				console.log(error);
				return null;
			}
		);

	callback(result);

	return result;
}

export default fetchApi;

