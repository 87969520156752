import React from 'react';

import { Container } from 'react-bootstrap';
import PathHeader from './PathHeader';
import Header from './Header';


function PageLayout(props) {

    return (
        <div>
            <Header setLoggedIn={props.setLoggedIn} />
            <PathHeader path={props.path} />

            <Container fluid className="my-4 px-4">
                {props.children}
            </Container>
        </div>
    );

}

export default PageLayout;