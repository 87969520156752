import { Spinner } from 'react-bootstrap';

function Loading() {
    return (
        <div className="text-center">
            <Spinner animation="border" variant="secondary" />
        </div>
    );
}

export default Loading;