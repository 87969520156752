import React from 'react';

import { Table } from 'react-bootstrap';
import { useTable, useSortBy, usePagination, useBlockLayout } from 'react-table';
import { FixedSizeList } from 'react-window'


function WindowTable(
    {
        columns,
        data
    }) {


    const defaultColumn = React.useMemo(
        () => ({
            width: 80,
        }),
        []
    );

    let widths = [];
    for (let i = 0; i < 17; i++) {
        widths.push(Math.round(Math.random() * 50 + 50));
    }

    // const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        totalColumnsWidth,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useBlockLayout
    )

    const RenderRow = React.useCallback(
        ({ index, style }) => {
            const row = rows[index]
            prepareRow(row)
            return (
                <tr
                    {...row.getRowProps({
                        style,
                    })}
                >
                    {row.cells.map((cell, index) => {
                        return (
                            <td {...cell.getCellProps()} className="td" style={{ width: widths[index] }}>
                                {cell.render('Cell')}
                            </td>
                        )
                    })}
                </tr>
            )
        },
        [prepareRow, rows]
    )

    const height = 400;
    // Render the UI for your table
    return (<>
        <table {...getTableProps()} className="table table-striped table-bordered table-hover table-sm">
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                        {headerGroup.headers.map((column, index) => (
                            <th {...column.getHeaderProps()} className="th" style={{ width: widths[index] }}>
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
        </table>
        <table {...getTableProps()} className="table table-striped table-bordered table-hover table-sm">
            <tbody {...getTableBodyProps()}>
                <FixedSizeList
                    height={height}
                    itemCount={rows.length}
                    itemSize={35}
                    width={totalColumnsWidth /*+ scrollBarSize*/}
                >
                    {RenderRow}
                </FixedSizeList>
            </tbody>
        </table>
    </>
    )



}


export default WindowTable;


