import React from 'react';

import Config from '../../config.json';
import { Stack, Card, Row, Container } from 'react-bootstrap';
import fetchApi from '../../functions/fetchApi';
import Loading from '../Loading';
import ClickableCard from '../ClickableCard';

import { reduceEventNameLength } from '../../functions/reduceEventNameLength';

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useParams, Link } from 'react-router-dom';

function DocumentsPage(props) {
    let { season, progressiveRoundNumber } = useParams();

    const [documents, setDocuments] = React.useState(null);
    const [eventInfo, setEventInfo] = React.useState(null);

    const { trackPageView, trackEvent } = useMatomo()
    React.useEffect(() => {
        trackPageView();
    }, [])

    React.useEffect(() => {

        fetchApi(Config.apiEndpoint + '/' + season + '/' + progressiveRoundNumber + '/info',

            (eventInfo) => {
                props.setPath((oldPath) =>
                    [{
                        text: <i className="bi bi-house-door"></i>,
                        url: '/',
                    }, {
                        text: season,
                        url: '/' + season,
                    }, {
                        text: reduceEventNameLength(eventInfo.name),
                        url: '/' + season + '/' + progressiveRoundNumber,
                    }, {
                        text: 'Documenti',
                        url: '/' + season + '/' + progressiveRoundNumber + '/documents',
                        disabled: true,
                    }]
                );

                setEventInfo(eventInfo);
            });

    }, []);


    React.useEffect(() => {
        fetchApi(Config.apiEndpoint + '/' + season + '/' + progressiveRoundNumber + '/pdf', (documents) => setDocuments(documents));
    }, []);


    if (documents === null || eventInfo === null) {
        return <Loading />
    }


    const dateRow = (
        <Row>
            <span>
                <span>
                    <i className="bi bi-calendar3"></i>
                </span>
                <span>&nbsp;</span>
                <span className='ml-2'>
                    {
                        new Date(documents.event.date)
                            .toLocaleDateString(
                                "it-IT",
                                { year: 'numeric', month: 'long', day: 'numeric' },
                            )
                    }
                </span>
            </span>
        </Row>
    );


    return (<>
        <Row className="row row-cols-1 mb-4">
            <Link to='' className="col clickable-card">
                <ClickableCard
                    title={"Elenco documenti"}
                    subtitles={[
                        documents.event.officialName,
                        documents.event.location + ' • ' + documents.event.country,
                        dateRow
                    ]}
                    rightColumn={<i className="bi bi-info-circle"></i>}
                    text={"Sono presenti " + documents.list.length + " documenti consultabili."}
                ></ClickableCard>
            </Link>
        </Row>


        <Row className="row row-cols-1 row-cols-md-2 row-cols-xxl-3 g-4 mt-4">
            {
                documents['list'].slice(0).reverse().map(document => {

                    // check how much time is elapsed from the publishment
                    const timeDifference = Math.round((new Date() - new Date(document.date)) / 1000);
                    let dateRow = undefined, timeRow = undefined, timeAgoRow = undefined, color = undefined;
                    const maxDateConversion = 86400;

                    const path = document.path.replace('/pdf/', '/documents/');

                    const colors = {
                        OFFENCE: 'danger',
                        SUMMON: 'warning',
                        DECISION: 'primary'
                    };

                    dateRow = (
                        <Row>
                            <span>
                                <span>
                                    <i className="bi bi-calendar3"></i>
                                </span>
                                <span>&nbsp;</span>
                                <span className='ml-2'>
                                    {
                                        new Date(document.date)
                                            .toLocaleDateString(
                                                "it-IT",
                                                { year: 'numeric', month: 'long', day: 'numeric' },
                                            )
                                    }
                                </span>
                            </span>
                        </Row>
                    );

                    timeRow = (
                        <Row>
                            <span>
                                <span>
                                    <i className="bi bi-clock"></i>
                                </span>
                                <span>&nbsp;</span>
                                <span className='ml-2'>
                                    {
                                        new Date(document.date)
                                            .toLocaleTimeString(
                                                "it-IT",
                                                { hour: 'numeric', minute: 'numeric' },
                                            )
                                    }
                                </span>
                            </span>
                        </Row>
                    );

                    if (Math.abs(timeDifference) <= maxDateConversion) {
                        timeAgoRow = (
                            <Row>
                                <span>
                                    <span>
                                        <i className="bi bi-hourglass-split"></i>
                                    </span>
                                    <span>&nbsp;</span>
                                    <span className='ml-2'>
                                        {
                                            Math.floor(Math.abs(timeDifference) / 3600) + " ore e " +
                                            Math.floor(Math.abs(timeDifference) % 3600 / 60) + " minuti fa"
                                        }
                                    </span>
                                </span>
                            </Row>
                        );

                    }

                    return (
                        <Link to={path} className="col clickable-card" key={path}>
                            <ClickableCard
                                title={document.name}
                                subtitles={[
                                    dateRow,
                                    timeRow,
                                    timeAgoRow
                                ]}
                                color={colors[document.type]}
                            //text=''
                            ></ClickableCard>
                        </Link>
                    )
                })
            }
        </Row>
    </>);
}

export default DocumentsPage;