import React from 'react';

import Config from '../../config.json';
import { Container, Stack, Card, Row, Col } from 'react-bootstrap';
import fetchApi from '../../functions/fetchApi';
import Loading from '../Loading';

import { useParams, Link } from 'react-router-dom';
import ClickableCard from '../ClickableCard';

import { useMatomo } from '@datapunt/matomo-tracker-react'

function EventsPage(props) {
    let { season } = useParams();
    const [events, setEvents] = React.useState(null);

    const { trackPageView, trackEvent } = useMatomo()
    React.useEffect(() => {
        trackPageView();
    }, [])

    React.useEffect(() => {
        fetchApi(Config.apiEndpoint + '/' + season + '/events', (events) => setEvents(events.filter((event) => event.supported === true)));

        props.setPath((oldPath) =>
            [{
                text: <i className="bi bi-house-door"></i>,
                url: '/',
            }, {
                text: season,
                url: '/' + season,
                disabled: true,
            }]
        );
    }, []);


    if (events === null) {
        return <Loading />
    }


    let min = null;
    events.map((event, index) => {
        const diffTime = Math.round((new Date(event.date) - new Date()) / (1000 * 60 * 60 * 24));
        if (diffTime < 0 && Math.abs(diffTime) < 7 || diffTime >= 0 && Math.abs(diffTime) < 3) min = index;
    });


    const DateRow = (props) => {

        return (
            <Row>
                <span>
                    <span>
                        <i className="bi bi-calendar3"></i>
                    </span>

                    <span>&nbsp;</span>

                    <span className='ml-2'>
                        {
                            new Date(props.date)
                                .toLocaleDateString(
                                    "it-IT",
                                    { month: 'long', day: 'numeric' },
                                )
                        }
                    </span>
                </span>
            </Row>
        );
    }


    return (
        // may want to add the class row-cols-xxl-3 to show three columns on big displays.
        <Row className="row row-cols-1 row-cols-md-2 g-4">
            {
                events.map((event, index) =>

                    <Link to={'/' + season + '/' + event.progressiveRoundNumber} key={event.progressiveRoundNumber} className="col clickable-card">
                        <ClickableCard
                            title={event.officialName}

                            subtitles={[
                                event.location,
                                event.country,
                                <DateRow date={event.date} />
                            ]}

                            color={index == min ? 'success' : undefined}

                            labels={
                                event.format == 'testing' ? [['Test', 'secondary']] : event.format == 'sprint' ? [['Sprint', 'danger']] : null
                            }

                            rightColumn={(index == min) ? <i className="bi bi-clock-history text-right text-success"></i> : undefined}

                        //text=''
                        />
                    </Link>

                )
            }
        </Row>
    );

}

export default EventsPage;