import React from 'react';

import { Table } from 'react-bootstrap';
import { useTable, useSortBy, usePagination, useBlockLayout } from 'react-table';




const defaultPropGetter = () => ({})

function BasicTable(
    {
        columns,
        data,
        tableClass,
        renderRowSubComponent,
        getHeaderProps = defaultPropGetter,
        getColumnProps = defaultPropGetter,
        getRowProps = defaultPropGetter,
        getCellProps = defaultPropGetter,
        getUsePagination = false,
        getColumnHiding = false,
        defaultPageSize = 15,
        rowsPerPage = [10, 20, 30, 40, 50]
    }) {


    // Use the useTable Hook to send the columns and data to build the table



    const {
        getTableProps, // table props from react-table
        getTableBodyProps, // table body props from react-table
        headerGroups, // headerGroups, if your table has groupings
        rows, // rows for the table based on the data passed
        prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)


        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        allColumns,
        getToggleHideAllColumnsProps,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: defaultPageSize },
    }, useSortBy, usePagination);


    const elem = (getUsePagination == 'true') ? page : rows;

    const columnSelection = getColumnHiding ? (
        <div>
            <div>

                All
            </div>
            {allColumns.map(column => (
                <div key={column.id}>
                    <label>
                        <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                        {column.id}
                    </label>
                </div>
            ))}
            <br />
        </div>
    ) : '';

    const table = (
        <Table responsive striped bordered hover {...getTableProps()} className={tableClass}>

            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                    {column.isSorted
                                        ? column.isSortedDesc
                                            ? (<><span>&nbsp;</span><i className="bi bi-caret-down-fill"></i></>)
                                            : (<><span>&nbsp;</span><i className="bi bi-caret-up-fill"></i></>)
                                        : ''}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>


            <tbody {...getTableBodyProps()}>
                {elem.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>

                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps([
                                    {
                                        className: cell.column.className,
                                        style: cell.column.style,
                                    },
                                    getColumnProps(cell.column),
                                    getCellProps(cell),
                                ])}>{cell.render("Cell")}</td>;
                            })}
                        </tr>
                    );
                }
                )
                }
            </tbody>

        </Table>
    );

    const paginationGui = (
        <div className="pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
            </button>{' '}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
            </button>{' '}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
            </button>{' '}
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {'>>'}
            </button>{' '}
            <span>
                Pagina{' '}
                <strong>
                    {pageIndex + 1} di {pageOptions.length}
                </strong>{' '}
            </span>
            <span>
                | Go to page:{' '}
                <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={e => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                        gotoPage(page)
                    }}
                    style={{ width: '100px' }}
                />
            </span>{' '}
            <select
                value={pageSize}
                onChange={e => {
                    setPageSize(Number(e.target.value))
                }}
            >
                {rowsPerPage.map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                        Mostra {pageSize}
                    </option>
                ))}
            </select>
        </div >
    );


    return (
        <>
            {columnSelection}
            {table}
            {getUsePagination == 'true' ? paginationGui : ''}
        </>


    );



}


export default BasicTable;






/*
        return (
            <table {...getTableProps()}>
    
                <thead>
    
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                            ))}
                        </tr>
                    ))}
    
                </thead>
    
    
                <tbody {...getTableBodyProps()}>
    
    
    
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                })}
                            </tr>
                        );
                    })}
    
    
    
                </tbody>
    
    
    
    
            </table>
        );
    
        */