import React, { useState, useLayoutEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";

// pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;










export default function OnePagePdf(props) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

    const [factor, setFactor] = useState(1);
    const [pageWidth, pageHeight] = useWindowSize();

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            const updateSize = () => {
                setSize([window.innerWidth, window.innerHeight]);
                resizeCanvas();
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }


    function resizeCanvas() {
        function waitForElm(selector) {
            return new Promise(resolve => {
                if (document.querySelector(selector)) {
                    return resolve(document.querySelector(selector));
                }

                const observer = new MutationObserver(mutations => {
                    if (document.querySelector(selector)) {
                        resolve(document.querySelector(selector));
                        observer.disconnect();
                    }
                });

                observer.observe(document.body, {
                    childList: true,
                    subtree: true
                });
            });
        }
        waitForElm('canvas.react-pdf__Page__canvas').then((elm) => {
            const pdfCanvas = document.querySelectorAll('canvas.react-pdf__Page__canvas');
            for (let i = 0; i < pdfCanvas.length; i++) {
                pdfCanvas[i].getContext("2d").scale(1 / factor, 1 / factor);
            }

        });

    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);

        // resizeCanvas();
        // resizeCanvas();
    }



    const { pdf, pdfWrapper } = props;


    let pdfWidth = () => {
        const pdfContainer = document.getElementById('pdfContainer');
        if (pdfContainer == undefined) return undefined;
        const canvasList = document.querySelectorAll('div.react-pdf__Page');
        for (let i = 0; i < canvasList.length; i++) {
            canvasList[i].style.height = pdfContainer.clientWidth * factor * Math.sqrt(2) + "px";
        }
        return pdfContainer.clientWidth * factor;
    };



    return (
        <>
            <Document
                file={pdf}
                onLoadSuccess={onDocumentLoadSuccess}
            //style={{ width: '100%', height: '100vh' }}
            >


                {Array.from(
                    new Array(numPages),
                    (el, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            scale={factor}
                            width={pdfWidth()}
                        />
                    ),
                )}
            </Document>
        </>
    );
}
