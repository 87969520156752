import React from 'react';

import Config from '../../config.json';
import { Stack, Card, Row, Col } from 'react-bootstrap';
import fetchApi from '../../functions/fetchApi';
import Loading from '../Loading';

import { reduceEventNameLength } from '../../functions/reduceEventNameLength';
import { reduceSessionNameLength } from '../../functions/reduceSessionNameLength';

import { useParams, Link } from 'react-router-dom';

import { useMatomo } from '@datapunt/matomo-tracker-react'


function SessionChoicePage(props) {
    let { season, progressiveRoundNumber, progressiveSessionNumber } = useParams();

    const [sessionInfo, setSessionInfo] = React.useState(null);

    const { trackPageView, trackEvent } = useMatomo()
    React.useEffect(() => {
        trackPageView();
    }, [])

    React.useEffect(() => {
        fetchApi(Config.apiEndpoint + '/' + season + '/' + progressiveRoundNumber + '/' + progressiveSessionNumber + '/info',
            (sessionInfo) => {
                props.setPath((oldPath) =>
                    [{
                        text: <i className="bi bi-house-door"></i>,
                        url: '/',
                    }, {
                        text: season,
                        url: '/' + season,
                    }, {
                        text: reduceEventNameLength(sessionInfo.eventName),
                        url: '/' + season + '/' + progressiveRoundNumber,
                    }, {
                        text: 'Sessioni',
                        url: '/' + season + '/' + progressiveRoundNumber + '/sessions',
                    },
                    {
                        text: reduceSessionNameLength(sessionInfo.sessionName),
                        url: '/' + season + '/' + progressiveRoundNumber + '/' + progressiveSessionNumber,
                        disabled: true,
                    }]
                );
                setSessionInfo(sessionInfo);
            });
    }, []);

    if (sessionInfo === null) {
        return <Loading />
    }

    return (

        <Stack direction="vertical" gap={4}>

            <Link to={'/' + season + '/' + progressiveRoundNumber + '/' + progressiveSessionNumber + '/laps'} key='drivers' className="clickable-card">
                <Card className="shadow choice-card">
                    <Card.Body>

                        <Row>
                            <Col s={10} className="d-flex flex-column justify-content-center">
                                <Card.Title className="my-0">
                                    Giri
                                </Card.Title>
                            </Col>

                            <Col s={2} className="fs-1 text-end">
                                <i className="bi bi-stopwatch"></i>
                            </Col>
                        </Row>

                    </Card.Body>
                </Card>
            </Link>


            <Link to={'/' + season + '/' + progressiveRoundNumber + '/' + progressiveSessionNumber + '/raceControl'} key='raceControl' className="clickable-card">
                <Card className="shadow choice-card">
                    <Card.Body>

                        <Row>
                            <Col s={10} className="d-flex flex-column justify-content-center">
                                <Card.Title className="my-0">
                                    Direzione gara
                                </Card.Title>
                            </Col>

                            <Col s={2} className="fs-1 text-end">
                                <i className="bi bi-headset"></i>
                            </Col>
                        </Row>

                    </Card.Body>
                </Card>
            </Link>

        </Stack>

    );
}

export default SessionChoicePage;
