import React from 'react';

import Config from '../../config.json';

import { Stack, Card, Row, Col } from 'react-bootstrap';

import fetchApi from '../../functions/fetchApi';
import Loading from '../Loading';
import BasicTable from '../BasicTable';
import MessageBox from '../MessageBox';

import { reduceEventNameLength } from '../../functions/reduceEventNameLength';
import { reduceSessionNameLength } from '../../functions/reduceSessionNameLength';

import FlagSymbol from '../FlagSymbol';

import { useParams } from 'react-router-dom';

import { useMatomo } from '@datapunt/matomo-tracker-react'

function RaceControlPage(props) {
    let { season, progressiveRoundNumber, progressiveSessionNumber } = useParams();

    const [messages, setMessages] = React.useState(null);
    const [sessionInfo, setSessionInfo] = React.useState(null);

    const { trackPageView, trackEvent } = useMatomo()
    React.useEffect(() => {
        trackPageView();
    }, [])


    React.useEffect(() => {
        fetchApi(
            Config.apiEndpoint + '/' + season + '/' + progressiveRoundNumber + '/' + progressiveSessionNumber + '/raceControlMessages', (messages) => setMessages(messages)
        );

        fetchApi(Config.apiEndpoint + '/' + season + '/' + progressiveRoundNumber + '/' + progressiveSessionNumber + '/info',
            (sessionInfo) => {
                props.setPath((oldPath) =>
                    [{
                        text: <i className="bi bi-house-door"></i>,
                        url: '/',
                    }, {
                        text: season,
                        url: '/' + season,
                    }, {
                        text: reduceEventNameLength(sessionInfo.eventName),
                        url: '/' + season + '/' + progressiveRoundNumber,
                    }, {
                        text: 'Sessioni',
                        url: '/' + season + '/' + progressiveRoundNumber + '/sessions',
                    }, {
                        text: reduceSessionNameLength(sessionInfo.sessionName),
                        url: '/' + season + '/' + progressiveRoundNumber + '/' + progressiveSessionNumber,
                    }, {
                        text: 'Direzione gara',
                        url: '/' + season + '/' + progressiveRoundNumber + '/' + progressiveSessionNumber + '/raceControl',
                        disabled: true,
                    }]
                );

                setSessionInfo(sessionInfo);
            });

    }, []);


    const columns = React.useMemo(
        () => [
            {
                Header: 'Tempo',
                accessor: 'time', // accessor is the "key" in the data
            },
            {
                Header: 'Tipo',
                accessor: (message, index) => <FlagSymbol type={message.type} />
            },
            {
                Header: 'Messaggio',
                accessor: 'message',
            },
        ],
        []
    );


    if (messages === null || sessionInfo === null) {
        return <Loading />
    }


    if (messages.length == 0) {
        return (
            <MessageBox
                text={
                    <span>I dati di questa sessione non sono al momento dispoinibili.</span>
                }
                variant='warning'
                rightColumn={(<i className="bi bi-info-circle-fill"></i>)}

            />
        )
    }

    return (

        <BasicTable columns={columns} data={messages} />

    );

}

export default RaceControlPage;
