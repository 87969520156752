import React from 'react';

import Config from '../../config.json';
import { Stack, Card, Button, Row, Col, Container, Modal, Form } from 'react-bootstrap';
import fetchApi from '../../functions/fetchApi';
import Loading from '../Loading';
import ClickableCard from '../ClickableCard';
import BasicTable from '../BasicTable';
import WindowTable from '../WindowTable';


import { useParams, Link } from 'react-router-dom';
import { render } from '@testing-library/react';




function StatisticsTable(columns, statisticsData) {
    console.log("Table render", statisticsData);
    return (<div><WindowTable
        tableClass={'table-striped table-bordered table-hover table-sm'}
        columns={columns}
        data={statisticsData}
        getUsePagination='false'
        getCellProps={(cellInfo) => {

            if (cellInfo.column.parent.id == 'valuesData') {
                if (cellInfo.value == 0)
                    return { className: 'table-danger' }
                else
                    return { className: 'table-success' }
            }
            return { className: 'sticky-top' }
        }}
    /></div>);
}


function StatisticsPage(props) {

    const [apiRequestDone, setApiRequestDone] = React.useState(false);
    const [statisticsData, setStatisticsData] = React.useState(null);

    const [selectedYear, setSelectedYear] = React.useState(null);

    const [rowSelection, setRowSelection] = React.useState(null);





    React.useEffect(async () => {
        const apiUrl = Config.apiEndpoint + '/statistics/data';
        if (apiRequestDone === false) {
            await fetchApi(apiUrl, (data) => {
                setStatisticsData([...data]);
                setApiRequestDone(() => true);
            });
        }
    }, []);

    let columns = [
        {
            id: 'generalData',
            Header: 'Giro',
            columns: [
                {
                    id: 'season',
                    Header: 'Stagione',
                    accessor: elem => elem['season'],
                    aggregate: 'count',
                }, {
                    id: 'round',
                    Header: 'Evento',
                    accessor: elem => elem['round'],
                },
                {
                    id: 'session',
                    Header: 'Session',
                    accessor: elem => elem['session'],
                },
                {
                    id: 'selection',
                    Header: '',
                    accessor: (statistic, index) => {
                        return (
                            <span className={((rowSelection == index) ? 'text-success' : 'text-danger')}>
                                <i className={"bi bi-cloud-arrow-down-fill"} onClick={() => {
                                    setRowSelection(index);
                                    console.log("Premuto", index);
                                }}></i>
                            </span>

                        );
                    },
                    disableSortBy: true,
                }

            ],
        }, {
            id: 'valuesData',
            Header: 'Dati',

            columns: [
                /*
                {
                    id: 'drivers',
                    Header: 'Piloti',
                    accessor: elem => elem['drivers'],
                    disableSortBy: true,
                },
                {
                    id: 'gps',
                    Header: 'GPS',
                    accessor: elem => elem['gps'],
                    disableSortBy: true,
                },
                {
                    id: 'history',
                    Header: 'History',
                    accessor: elem => elem['history'],
                    disableSortBy: true,
                },
                {
                    id: 'messages',
                    Header: 'Messaggi',
                    accessor: elem => elem['messages'],
                    disableSortBy: true,
                },
                {
                    id: 'pits',
                    Header: 'Pits',
                    accessor: elem => elem['pits'],
                    disableSortBy: true,
                },
                {
                    id: 'speed',
                    Header: 'Velocità',
                    accessor: elem => elem['speed'],
                    disableSortBy: true,
                },
                {
                    id: 'stints',
                    Header: 'Stints',
                    accessor: elem => elem['stints'],
                    disableSortBy: true,
                },
                {
                    id: 'telemetry',
                    Header: 'Telemetria',
                    accessor: elem => elem['telemetry'],
                    disableSortBy: true,
                },
                {
                    id: 'timeRemaining',
                    Header: 'Tempo rimanente',
                    accessor: elem => elem['timeRemaining'],
                    disableSortBy: true,
                },
                {
                    id: 'timeSync',
                    Header: 'Time sync',
                    accessor: elem => elem['timeSync'],
                    disableSortBy: true,
                },
                {
                    id: 'trackStatuses',
                    Header: 'Stato pista',
                    accessor: elem => elem['trackStatuses'],
                    disableSortBy: true,
                },
                {
                    id: 'trackTimes',
                    Header: 'Tempo pista',
                    accessor: elem => elem['trackTimes'],
                    disableSortBy: true,
                },
                {
                    id: 'weather',
                    Header: 'Meteo',
                    accessor: elem => elem['weather'],
                    disableSortBy: true,
                },
                */
            ],

        },
    ];


    if (apiRequestDone === false) {
        return <Loading />
    }

    let years = () => {
        let vector = [];
        let last = -1;
        statisticsData.forEach(element => {
            if (element['season'] != last) {
                last = element['season'];
                vector.push(last);
            }
        });
        return vector;
    }



    const selection = () => {
        let yearVector = years().reverse();
        if (selectedYear === null)
            setSelectedYear(yearVector[0]);
        return (
            <Form.Select
                onChange={e => {
                    const page = e.target.value;
                    setSelectedYear(page)
                }}>
                {yearVector.map(year => (
                    <option key={year} value={year}>
                        Stagione {year}
                    </option>
                ))}
            </Form.Select>
        );
    }




    let variableValues = {
        drivers: 'Piloti', gps: 'GPS', history: 'Hisotry', messages: 'Messaggi', pits: 'Pits',
        speed: 'Velocità', stints: 'Stints', telemetry: 'Telemetra', timeRemaining: 'Tempo Rimanente',
        timeSync: 'Time Sync', trackStatuses: 'Stato pista', trackTimes: 'Tempo pista', weather: 'Meteo'
    };


    if (columns[1]['columns'].length == 0) {
        for (const key in variableValues) {
            columns[1]['columns'].push({
                id: key,
                Header: variableValues[key],
                accessor: elem => elem[key],
                disableSortBy: true,
            });
        }
    }




    return (
        <>
            {selection()}
            hai selezionato la riga {rowSelection}
            {StatisticsTable(columns, statisticsData/*.filter(event => event['season'] == selectedYear)*/)}


        </>
    );

}

export default StatisticsPage;