function loadTelegramWidget(callback) {

    const existingScript = document.getElementById('telegram-widget');

    if (!existingScript) {
        const script = document.createElement('script');
        script.src = 'https://telegram.org/js/telegram-widget.js?7';
        script.id = 'telegram-widget';
        document.body.appendChild(script);
        script.onload = () => {
            if (callback) callback();
        };
    }

    if (existingScript && callback) callback();

};

export default loadTelegramWidget;