import React from 'react';
import { Container, Breadcrumb, Navbar, OverlayTrigger, Tooltip, Overlay } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LZ77 from '../functions/LZ77';



function PathHeader(props) {
    let i = 0;


    const keys = {
        'season': 'y',
        'progressiveRoundNumber': 'r',
        'progressiveSessionNumber': 's',
        'racingNumber': 'd',
        'lapIndex': 'l',
    };

    const copyToClipboardTooltipRef = React.useRef(null);
    const [showCopyToClipboardTooltip, setShowCopyToClipboardTooltip] = React.useState(false);

    const sendUrlToClipboard = () => {
        let lastPathItem = props.path[props.path.length - 1];
        let textToCopy = window.location.href;

        if ('isShowingCharts' in lastPathItem && lastPathItem.isShowingCharts === true) {
            // need to append the lap selection
            let lapsString = (new LZ77()).compress(LZ77.vectorRemoveKeys(localStorage.getItem('selectedLaps'), keys));
            textToCopy += '/' + lapsString;
        }

        copyTextToClipboard(textToCopy);

        setShowCopyToClipboardTooltip(true);
        setTimeout(() => {
            setShowCopyToClipboardTooltip(false);
        }, 1000);
    }

    const copyTextToClipboard = (text) => {
        var textArea = document.createElement("textarea");

        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = -9999999;
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        textArea.value = text;

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (err) {
            // TODO: check what to do if not able to copy text
        }

        document.body.removeChild(textArea);
    }



    return (
        <Navbar sticky="top" bg="dark" className="py-1">
            <Container fluid className="d-flex px-4">

                <Breadcrumb className="me-auto">
                    {
                        props.path.map((pathElement) => {
                            let itemClasses = 'breadcrumb-item';
                            let content;

                            if ('disabled' in pathElement && pathElement.disabled === true) {

                                itemClasses += ' active';
                                content =
                                    <span>
                                        {pathElement.text}
                                    </span>

                            } else {
                                content = (
                                    <Link to={pathElement.url}>
                                        {pathElement.text}
                                    </Link>
                                );
                            }

                            i++;

                            return (
                                <div key={i} className={itemClasses}>
                                    {content}
                                </div>
                            );
                        })
                    }
                </Breadcrumb>

                <Link to="/charts" className="sticky-navbar-icon mx-1">
                    <i className="bi bi-graph-up"></i>
                </Link>


                <span
                    ref={copyToClipboardTooltipRef}
                    role="button"
                    className="sticky-navbar-icon mx-1"
                    onClick={sendUrlToClipboard}
                >
                    <i className="bi bi-share-fill"></i>
                </span>

                <Overlay
                    target={copyToClipboardTooltipRef.current}
                    show={showCopyToClipboardTooltip}
                    placement="bottom-end"
                >
                    {(props) => (
                        <Tooltip {...props}>
                            Copiato
                        </Tooltip>
                    )}
                </Overlay>


            </Container>
        </Navbar>
    );




}


export default PathHeader;
