import React from 'react';

import Config from '../../config.json';
import { Stack, Card, Row, Col } from 'react-bootstrap';
import fetchApi from '../../functions/fetchApi';
import Loading from '../Loading';

import { useParams, Link } from 'react-router-dom';

import { reduceEventNameLength } from '../../functions/reduceEventNameLength';

import { useMatomo } from '@datapunt/matomo-tracker-react'

function EventChoicePage(props) {
    let { season, progressiveRoundNumber } = useParams();

    const [eventInfo, setEventInfo] = React.useState(null);
    const [eventMapPdf, setEventMapPdf] = React.useState(null);


    const { trackPageView, trackEvent } = useMatomo()
    React.useEffect(() => {
        trackPageView();
    }, [])

    React.useEffect(() => {

        fetchApi(Config.apiEndpoint + '/' + season + '/' + progressiveRoundNumber + '/info',
            (eventInfo) => {
                props.setPath((oldPath) =>
                    [{
                        text: <i className="bi bi-house-door"></i>,
                        url: '/',
                    }, {
                        text: season,
                        url: '/' + season,
                    }, {
                        text: reduceEventNameLength(eventInfo.name),
                        url: '/' + season + '/' + progressiveRoundNumber,
                        disabled: true,
                    }]
                );

                setEventInfo(eventInfo);
            });



    }, []);

    React.useEffect(() => {
        fetchApi(Config.apiEndpoint + '/' + season + '/' + progressiveRoundNumber + '/circuitMapPdf',
            (mapPdf) => {
                if (mapPdf instanceof Array || mapPdf instanceof Object) {
                    setEventMapPdf(mapPdf);
                }
            });
    }, [season, progressiveRoundNumber]);


    if (eventInfo === null) {
        return <Loading />
    }


    return (

        <Row className='row-cols-1 row-cols-md-2 g-4'>
            <Col>
                <Link to={'/' + season + '/' + progressiveRoundNumber + '/sessions'} key='drivers' className="clickable-card">
                    <Card className="shadow choice-card">
                        <Card.Body>

                            <Row>
                                <Col s={10} className="d-flex flex-column justify-content-center">
                                    <Card.Title className="my-0">
                                        Sessioni
                                    </Card.Title>
                                </Col>

                                <Col s={2} className="fs-1 text-end">
                                    <i className="bi bi-clock"></i>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Link>
            </Col>


            <Col>
                <Link to={'/' + season + '/' + progressiveRoundNumber + '/documents'} key='documents' className="clickable-card">
                    <Card className="shadow choice-card">
                        <Card.Body>

                            <Row>
                                <Col s={10} className="d-flex flex-column justify-content-center">
                                    <Card.Title className="my-0">
                                        Documenti
                                    </Card.Title>
                                </Col>

                                <Col s={2} className="fs-1 text-end">
                                    <i className="bi bi-file-text"></i>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Link>
            </Col>

            {eventMapPdf != null && (<Col>
                <Link to={eventMapPdf.path} key='documents' className="clickable-card">
                    <Card className="shadow choice-card">
                        <Card.Body>
                            <Row>
                                <Col s={10} className="d-flex flex-column justify-content-center">
                                    <Card.Title className="my-0">
                                        Mappa circuito
                                    </Card.Title>
                                </Col>

                                <Col s={2} className="fs-1 text-end">
                                    <i className="bi bi-map"></i>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Link>
            </Col>)}
        </Row>

    );
}

export default EventChoicePage;
