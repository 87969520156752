import React from 'react';

import Config from '../../config.json';
import { Nav, Button, Row, Col } from 'react-bootstrap';
import fetchApi from '../../functions/fetchApi';
import Loading from '../Loading';

import LapsTable from '../LapsTable';

import ClickableCard from '../ClickableCard';
import LZ77 from '../../functions/LZ77';

import { useParams, Link } from 'react-router-dom';

import ValueChart from '../charts/ValueChart';
import BoolChart from '../charts/BoolChart';
import MixedChart from '../charts/MixedChart';

import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useMatomo } from '@datapunt/matomo-tracker-react'



const colorScheme = [
    "#FD7272", "#54a0ff", "#00d2d3",
    "#1abc9c", "#2ecc71", "#3498db", "#9b59b6", "#34495e",
    "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#2c3e50",
    "#f1c40f", "#e67e22", "#e74c3c", "#ecf0f1", "#95a5a6",
    "#f39c12", "#d35400", "#c0392b", "#bdc3c7", "#7f8c8d",
    "#55efc4", "#81ecec", "#74b9ff", "#a29bfe", "#dfe6e9",
    "#00b894", "#00cec9", "#0984e3", "#6c5ce7", "#ffeaa7",
    "#fab1a0", "#ff7675", "#fd79a8", "#fdcb6e", "#e17055",
    "#d63031", "#feca57", "#5f27cd", "#54a0ff", "#01a3a4",
    "#25CCF7",
]


const keys = {
    'season': 'y',
    'progressiveRoundNumber': 'r',
    'progressiveSessionNumber': 's',
    'racingNumber': 'd',
    'lapIndex': 'l',
};


const prepareBooleanDataForCharts = (booleanData, type, label) => {

    booleanData = booleanData.filter(

        (telemetrySample, index) => (
            (index === 0 && telemetrySample[type] === true)
            || (index === booleanData.length - 1)
            || (index > 0 && index < booleanData.length - 1 && booleanData[index - 1][type] !== telemetrySample[type])
        )

    );

    let newBooleanData = [];

    for (let i = 0; i < booleanData.length - 1; i += 2) {
        newBooleanData.push({
            y: label,
            x: [booleanData[i].distance, booleanData[i + 1].distance],
        });
    }

    return newBooleanData;
}


function ChartsPage(props) {
    let { newLocalStorage } = useParams();

    const [laps, setLaps] = React.useState(null);
    const [selectedTab, setSelectedTab] = React.useState('speed');

    const [isFullScreen, setIsFullScreen] = React.useState(false);

    const { trackPageView, trackEvent } = useMatomo()
    React.useEffect(() => {
        trackPageView();
    }, [])


    /* activating PathHeader last item (to allow users to go back to the previous page) */
    React.useEffect(() => {
        props.setPath((oldPath) => {
            let newPath = [...oldPath];
            newPath[newPath.length - 1].disabled = false;
            newPath[newPath.length - 1].isShowingCharts = true;
            return newPath;
        });
    }, []);


    const exitHandler = () => {
        if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
            setIsFullScreen(false);
        }
    }


    React.useEffect(() => {

        if (document.addEventListener) {
            // detect the full screen exit event

            const el = ['fullscreenchange', 'mozfullscreenchange', 'MSFullscreenChange', 'webkitfullscreenchange'];

            el.forEach(e => {
                document.removeEventListener(e, exitHandler);
                document.addEventListener(e, exitHandler, false);

                console.log("Aggiunto " + e);
            });
        }

    }, []);


    React.useEffect(async () => {
        let newLaps = [];

        let i = 0;

        for (const selectedLap of props.selectedLaps) {

            await fetchApi(
                Config.apiEndpoint + '/' + selectedLap.season + '/' + selectedLap.progressiveRoundNumber + '/' + selectedLap.progressiveSessionNumber + '/lapTelemetry/' + selectedLap.racingNumber + '/' + selectedLap.lapIndex,

                (lapData) => {

                    if (lapData.length <= 0) {
                        return;
                    }

                    let lapTelemetry = lapData.telemetry.filter((telemetrySample) => 'distance' in telemetrySample);

                    let lapId =
                        selectedLap.season
                        + '-' + selectedLap.progressiveRoundNumber
                        + '-' + selectedLap.progressiveSessionNumber
                        + '-' + selectedLap.racingNumber
                        + '-' + selectedLap.lapIndex;

                    let lapInfo = {
                        ...lapData.lapInfo,

                        season: selectedLap.season,
                        progressiveRoundNumber: selectedLap.progressiveRoundNumber,
                        progressiveSessionNumber: selectedLap.progressiveSessionNumber,
                        racingNumber: selectedLap.racingNumber,
                        lapIndex: selectedLap.lapIndex,
                    };



                    let lapDrs = lapTelemetry.map(
                        (telemetrySample) => ({
                            distance: telemetrySample.distance,
                            drs: (telemetrySample.drs >= 10)
                        })
                    );

                    lapDrs = prepareBooleanDataForCharts(lapDrs, 'drs', lapId);

                    let lapBrake = lapTelemetry.map(
                        (telemetrySample) => (
                            {
                                distance: telemetrySample.distance,
                                brake: telemetrySample.brake,
                            }
                        )
                    );

                    lapBrake = prepareBooleanDataForCharts(lapBrake, 'brake', lapId);


                    let lapSpeed = lapTelemetry.map(telemetrySample => ({
                        x: telemetrySample.distance,
                        y: telemetrySample.speed,
                    }));

                    let lapThrottle = lapTelemetry.map(telemetrySample => ({
                        x: telemetrySample.distance,
                        y: telemetrySample.throttle,
                    }));

                    let lapRpm = lapTelemetry.map(telemetrySample => ({
                        x: telemetrySample.distance,
                        y: telemetrySample.rpm,
                    }));

                    let lapGear = lapTelemetry.map(telemetrySample => ({
                        x: telemetrySample.distance,
                        y: telemetrySample.gear,
                    }));

                    newLaps.push({
                        'lapInfo': lapInfo,
                        'driverInfo': lapData.driverInfo,

                        'lineColor': colorScheme[i % colorScheme.length],

                        'speed': lapSpeed,
                        'throttle': lapThrottle,
                        'rpm': lapRpm,
                        'gear': lapGear,
                        'drs': lapDrs,
                        'brake': lapBrake,
                    });
                }
            );

            i++;
        }

        setLaps(newLaps);
    }, []);


    /* asking whether the user wants to overwrite previous selected laps */

    if (newLocalStorage !== undefined) {
        const newLaps = LZ77.vectorAddKeys((new LZ77()).decompress(newLocalStorage, true), keys);
        const oldLaps = props.selectedLaps;

        return (

            <ClickableCard
                title='Visualizzazione dei giri'
                rightColumn={<i className="bi bi-exclamation-circle-fill"></i>}
                text={(<>
                    <Row>
                        <Col>
                            {
                                "Stai per consultare " + newLaps.length + " giri provenienti dal link che hai aperto. " +
                                "Questo sovrascriverà la tua precedente selezione di " + props.selectedLaps.length + " giri. Vuoi proseguire?"
                            }
                        </Col>

                    </Row>
                    <Row className='mt-4'>

                        <Col className='col-md-6'>
                            <p className='d-grid gap-2'>
                                <Link to='/charts' role='button'>
                                    <Button variant="success" role="button" style={{ width: '100%' }} onClick={() => {
                                        props.setSelectedLaps((oldSelectedLaps) => {
                                            // decode the content of the url
                                            return newLaps;
                                        });
                                    }}>
                                        <i className="bi bi-check-lg"></i> Continua
                                    </Button>
                                </Link>
                            </p>
                        </Col>
                        <Col className='col-md-6'>
                            <span className='d-grid gap-2'>
                                <Link to='/charts' role='button'>
                                    <Button variant="danger" role="button" style={{ width: '100%' }}>
                                        <i className="bi bi-x-lg"></i> Annulla
                                    </Button>
                                </Link>
                            </span>
                        </Col>
                    </Row>
                </>)
                }
            >

            </ClickableCard>
        );
    }


    if (laps === null) {
        return <Loading />
    }


    const ChartTabs = () => {
        return (
            <Nav
                justify
                variant="pills"
                className="my-4"

                defaultActiveKey="speed"
                activeKey={selectedTab}
                onSelect={(key) => setSelectedTab(key)}
            >
                <Nav.Item>
                    <Nav.Link eventKey="speed">
                        Velocità e DRS
                    </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                    <Nav.Link eventKey="throttle">
                        Acceleratore e freno
                    </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                    <Nav.Link eventKey="rpm">
                        Giri motore
                    </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                    <Nav.Link eventKey="gear">
                        Cambio
                    </Nav.Link>
                </Nav.Item>
            </Nav>
        );
    }


    let chart;



    switch (selectedTab) {
        case 'speed':
            chart = <MixedChart laps={laps} title="Velocità e DRS" valueType="speed" booleanType="drs" />
            break;
        case 'throttle':
            chart = <MixedChart laps={laps} title="Acceleratore e freno" valueType="throttle" booleanType="brake" />
            break;
        case 'rpm':
            chart = <ValueChart laps={laps} title="Giri motore" valueType="rpm" />
            break;
        case 'gear':
            chart = <ValueChart laps={laps} title="Cambio" valueType="gear" isStepped={true} />
            break;
    }

    const toggleFullScreen = () => {

        if (isFullScreen) {
            // need to close the full screen
            if (document.exitFullscreen) document.exitFullscreen();
            else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
            else if (document.msExitFullscreen) document.msExitFullscreen();
            setIsFullScreen(false);
        } else {
            // make the element full screen
            const elem = document.getElementsByClassName('fullscreen')[0];
            if (elem.requestFullscreen) elem.requestFullscreen();
            else if (elem.webkitRequestFullscreen) elem.webkitRequestFullscreen();
            else if (elem.msRequestFullscreen) elem.msRequestFullscreen();

            // rotate the display, if possible
            const orientation = window.screen.orientation || window.screen.mozOrientation || window.screen.webkitOrientation || window.screen.msOrientation;
            orientation.lock("landscape-primary").then(success => { }, failure => { });
            setIsFullScreen(true);
        }
    }









    return (
        <div>
            <LapsTable
                laps={
                    laps.map(lap => ({ ...lap.lapInfo, driverInfo: lap.driverInfo, lineColor: lap.lineColor }))}
                setLaps={setLaps} selectedLaps={props.selectedLaps}
                setSelectedLaps={props.setSelectedLaps} showLinks={true} showColors={true} showDriverInfo={true} />

            <ChartTabs />

            <div className='fullscreen'>
                <Button onClick={() => toggleFullScreen()} variant='secondary'
                    className={isFullScreen ? 'opacity-25 btn-sm' : ''}
                    style={isFullScreen ? { position: 'absolute', right: '0', top: '0' } : {}}>
                    {isFullScreen ? (<i className="bi bi-fullscreen-exit"></i>) : (<i className="bi bi-fullscreen"></i>)}
                </Button>

                {chart}
            </div>

        </div >
    );

}


export default ChartsPage;









/*
const columns = React.useMemo(
    () => [
        {
            id: 'season',
            Header: 'Stagione',
            accessor: (lap, index) => lap.season,
            // sortType: 'number',
        },

        {
            id: 'round',
            Header: 'Round',
            accessor: (lap, index) => lap.progressiveRoundNumber,
            sortType: 'number',
        },

        {
            id: 'session',
            Header: 'Sessione',
            accessor: (lap, index) => lap.progressiveSessionNumber,
            // sortType: 'number',
        },

        {
            id: 'driver',
            Header: 'Pilota',
            accessor: (lap, index) => lap.racingNumber,
            // sortType: 'number',
        },

        {
            id: 'lapNumber',
            Header: 'N',
            accessor: (lap, index) => lap.lapNumber,
            // sortType: 'number',
        },

    ],
    [],
);
*/


/*
    React.useEffect(() => {
        if (newLocalStorage !== undefined) {
            return;

            props.setSelectedLaps((oldSelectedLaps) => {
                // decode the content of the url
                return LZ77.vectorAddKeys((new LZ77()).decompress(newLocalStorage, true), keys);
            });
            // Remove the part after /charts
            window.history.pushState("", "", '/charts');
        }
    }, []);
    */